import React, { useState,useEffect } from "react";
import Navbar from "../../Navbar/index";
import WebApplicationbanner from "../../../images/Web-Application-Header.png";
import WebApplication1 from "../../../images/webapplicationimg1.png";
import WebApplication2 from "../../../images/webapplicationimg2.png";
import Testimonial from "../../Testimonial/Testimonial";
import WebApplication from "../../../images/webapplication.svg";
import EcommerceSol from "../../../images/ecommercesol.svg";
import SocialMedia from "../../../images/socialmedia.svg";
import MobileApp from "../../../images/mobileapp.svg";
import Footer from "../../Footer/index";
import "./WebsiteApplication.css";
import Sidebar from '../../Sidebar/index';
import Button from "react-bootstrap/Button";
import BookFreeConsultation from "../../BookFreeConsultation/BookFreeConsultation";
import { Helmet } from "react-helmet";
import Enquiry from "../../EnquiryForm/Enquiry";
import ReactPixel from "react-facebook-pixel";


const cardOne = [
  {
    image: WebApplication,
    name: "Search Engine Optimization",
    link: "/ecomseoservice",
    desc:
      "Our SEO specialists are committed to creating better evolving strategies in-line with the latest search engine guidelines to maintain your website's ranking.",
  },
  {
    image: EcommerceSol,
    name: "Digital Marketing",
    link: "/socialmediamarketing",
    desc:
      "Digital marketing gives you infinite opportunities to grow your business. We have an expert digital marketing team to help you gain maximum out of your digital marketing.",
  },
  {
    image: EcommerceSol,
    name: "Google Ad",
    link: "/googlead",
    desc:
      "We help you manage your business google ads campaign more efficiently and effectively by leveraging our team of google ads experts.",
  },
];

const cardTwo = [
  {
    image: SocialMedia,
    name: "Lead Generation",
    link: "/performancemarketing",
    desc:
      "We are specialized in lead generation digital marketing, not only that but we also help you receive the lead in real-time So that you can immediately convert the lead to customer.",
  },
  {
    image: MobileApp,
    name: "Social Media Marketing",
    link: "/socialmediamarketing",
    desc:
      "Your business needs an experienced and expert digital marketing agency to ensure your business growth and handle all your social media channels.",
  },
  {
    image: MobileApp,
    name: "Email Marketing",
    link: "/emailmarketing",
    desc:
      "Email marketing has the highest ROI as compared to any other marketing. Yodiso will provide the best email marketing and campaign solution for your business.",
  },
];

function WebsiteApplication() {
  const [isOpen, setIsOpen] = useState(false);
  const [handlemodal, setHandleModal] = useState(false);

  const handleClose = () => {
    setHandleModal(false);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };
   useEffect(() => {
     const advancedMatching = { em: "madhav.mishra@yodiso.com" };
     const options = {
       autoConfig: true,
       debug: false,
     };
     ReactPixel.init(277473760122474, advancedMatching, options);
     ReactPixel.pageView();
   }, []);

  return (
    <div style={{ height: 2000 }} style={{ scrollbarWidth: "none" }}>
      <Helmet>
        <title>YODISO | WEB APPLICATION</title>
      </Helmet>
      <Enquiry/>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <div className="navpagecontainer">
        <img src={WebApplicationbanner} className="webbanner" />

        <div className="top-left"></div>
        <h2 className="centered">
          Latest Technology | User-Friendly | Low Maintenance
        </h2>
      </div>

      <div style={{ textAlign: "center", marginTop: 80 }}>
        <h1 className="servicesHeading">
          Website <span style={{ color: "blue" }}>Application</span>
        </h1>
      </div>

      <div className="briefContainerOne">
        <div>
          <img src={WebApplication1} className="imagewebapplicationone" />
        </div>
        <div className="briefingwebapplicationone">
          <h3 className="headerwebapplicationone">
            Your Requirements Our Development With Latest Technology
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            We are one of the most trusted web application development company
            in Mumbai. Yodiso provides a customs web application as per your
            requirements. We use the latest technology and write high-level code
            that constitutes your application and looks stunning and efficient.
            We provide end-to-end web application solutions for small, medium
            and any size of business across all industries. Because we use the
            latest technology and write high-end code together it makes your
            application more efficient and secure. We design and develop the web
            application which runs in all browsers across all devices and is
            easy to navigate.
          </p>
        </div>
      </div>

      <h3 className="additionalServiceHeading">
        Benefits of having Web Application:
      </h3>
      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={WebApplication} />
            <p className="boxPara">
              <a href="/ecomseoservice">Easy customizable and scalable</a>
            </p>
            <p className="boxSubPara">
              Our SEO specialists are committed to creating better evolving
              strategies in-line with the latest search engine guidelines to
              maintain your website's ranking.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">
              <a href="/socialmediamarketing">
                Can be accessed from anywhere 24/7
              </a>
            </p>
            <p className="boxSubPara">
              Digital marketing gives you infinite opportunities to grow your
              business. We have an expert digital marketing team to help you
              gain maximum out of your digital marketing.
            </p>
          </div>

          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">
              <a href="/googlead">Easy Installation and maintenance</a>
            </p>
            <p className="boxSubPara">
              We help you manage your business google ads campaign more
              efficiently and effectively by leveraging our team of google ads
              experts.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">
              <a href="/performancemarketing">High-level of security</a>
            </p>
            <p className="boxSubPara">
              We are specialized in lead generation digital marketing, not only
              that but we also help you receive the lead in real-time So that
              you can immediately convert the lead to customer.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">
              <a href="/socialmediamarketing">Highly efficient</a>
            </p>
            <p className="boxSubPara">
              Your business needs an experienced and expert digital marketing
              agency to ensure your business growth and handle all your social
              media channels.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">
              <a href="/emailmarketing">Cost Effective</a>
            </p>
            <p className="boxSubPara">
              Email marketing has the highest ROI as compared to any other
              marketing. Yodiso will provide the best email marketing and
              campaign solution for your business.
            </p>
          </div>
        </div>
      </div>
      <BookFreeConsultation showmodal={handlemodal} closemodal={handleClose} />
      <div className="serviceBtn">
        <Button
          className="serviceButton"
          variant="primary"
          onClick={() => setHandleModal(true)}
        >
          Book Free Consultation
        </Button>
      </div>

      <Testimonial />

      <h3 className="additionalServiceHeading">
        Additional Services to scale your business
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          {cardOne.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
        <div className="cardLayoutAdditional">
          {cardTwo.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default WebsiteApplication;
