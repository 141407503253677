import styled from "styled-components";


export const FooterContainer = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 480px) {
    height: 1770px !important;
  }
  @media screen and (max-width: 768px) {
    padding: 50px 0;
    height: 1610px;
  }
  
`;


export const Container = styled.div`
  display: flex;
  background-repeat: no-repeat;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;


export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: 400px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    z-index: 1;
    height: 100%;
    /* width: 200px; */
    margin-right: 5px;
    margin-left:0;
    padding: 0 24px;
    justify-content: center;

  }
`;


export const CompanyName = styled.h4`
  margin-bottom: 70px;
  max-width: 250px;
  margin-left: 80px;
  font-size: 25px;
  text-align: center;
  line-height: 24px;
  color: #fff;

  @media screen and (max-width: 480px) {
    font-size: 25px !important;
    width: 70px !important;
  }
  @media screen and (max-width: 768px) {
    margin-left: 5px;
  }
  @media screen and (max-width: 1024px) {
    margin-left: 90px;
    margin-top: 50px;
  }
`;

export const CompanyDescription = styled.p`
  margin-bottom: 82px;
  font-size: 18px;
  text-align: center;
  width: 400px;
  line-height: 24px;
  color: #fff;

  @media screen and (max-width: 480px) {
    text-align:justify;
    font-size: 18px !important;
    width: 300px !important;
  }
  @media screen and (max-width: 768px) {
    width: 200px;
    font-size: 15px;
  }
  @media screen and (max-width: 1024px) {
    width: 450px;
    font-size: 18px;
  }
`;


export const SubContainerInfoOne = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: 480px;
  width: 100%;
  max-width: 1100px;
  margin-top:-25px;
  margin-right: auto;
  margin-left: -20px;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    z-index: 1;
    margin-top:-30px !important;
    margin-left:10px !important;
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    margin-right: -40px;
  }
  @media screen and (max-width: 1024px) {
    margin-left: 1px;
    margin-top:-130px;
  }
`;


export const SubContainerInfoTwo = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: 480px;
  width: 100%;
  margin-top: -60px;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    z-index: 1;
    margin-left: 10px;
    margin-top: 30px !important;
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    margin-top: -30px;
  }
  @media screen and (max-width: 1024px) {
    margin-top: -150px;
  }
`;

export const SubContainerInfoThree = styled.div`
  display: flex;
  flex-direction: column;
  height: 480px;
  width: 100%;
  margin-top: 60px;
  max-width: 1100px;
  margin-right: auto;
  margin-left: -110px;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    margin-left: 10px !important;
    margin-top: 30px !important;
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    margin-top: -30px;
  }
  @media screen and (max-width: 1024px) {
    margin-top: -50px;
    margin-left: 2px;
  }
`;


export const Subtitle = styled.h4`
  margin-bottom: 35px;
  max-width: 250px;
  font-size: 24px;
  text-align: left;
  line-height: 24px;
  color: #fff;

  @media screen and (max-width: 480px) {
    font-size: 23px !important;
    width: 100% !important;
    margin-top: 40px !important;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const SubPara = styled.p`
  margin-bottom: 10px;
  width: 300px;
  font-size: 18px;
  text-align: left;
  line-height: 24px;
  color: #fff;

  &:hover {
    color: #8a2be2;
  }
  @media screen and (max-width: 480px) {
    font-size: 17px !important;
    width: 100% !important;
    text-align: left !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 18px;
    width: 500px;
  }
`;

export const Button = styled.button`
  margin-left: 50px;
  width: 200px;
  height: 35px;
  background-color: blue;
  color: white;

  @media screen and (max-width: 480px) {
    width: 150px !important;
    justify-content:center;
  }

  @media screen and (max-width: 1024px) {
    margin-left: 90px;
    width: 300px;
    margin-bottom: 40px;
  }
`;


export const FootContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CopyRight = styled.p`
  margin-bottom: 20px;
  font-size: 18px;
  text-align: center;
  line-height: 24px;
  color: #fff;

  @media screen and (max-width: 480px) {
    font-size: 15px;
    margin-top:20px;
  }
`;
