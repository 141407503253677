import React, { useState } from "react";
import "./BookFreeConsultation.css";
import { Modal, Form, Button,Col,InputGroup} from "react-bootstrap";
import CheckedTick from '../../images/checked.gif'


function BookFreeConsultation(props) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [help, setHelp] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [responseType, setResponseType] = useState("");
  const [smShow, setSmShow] = useState(false);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const handleClose = () => {
    props.closemodal()
  };

  const successAlert = () => (
    <Modal
      size="sm"
      show={smShow}
      onHide={() => setSmShow(false)}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Body  closeButton>
        <strong style={{textAlign:'center'}}>
          Thank you for contacting us, we will get back to you soon!
        </strong>
        <div className="successgif">
          <img src={CheckedTick} alt="loading..." width="200" height="200" />
        </div>
      </Modal.Body>
    </Modal>
  );


  const PostConsultation = (e) => {
    e.preventDefault();
    fetch("/bookconsultation", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        help,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setSubmitted(true);
        if (data.error) {
          setValidated(true);
          setName("");
          setPhone("");
          setEmail("");
          setHelp("");
        } else {
          handleClose()
          setSmShow(true);
          setResponseType("success");
          setName('')
          setPhone('')
          setEmail('')
          setHelp('')
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {submitted && (responseType === "error" ? "" : successAlert())}

      <Modal show={props.showmodal} onHide={props.closemodal}>
        <Modal.Header closeButton>
          <Modal.Title className="modalheading">
            Book Free Consultation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Row style={{ display: "flex", flexDirection: "column" }}>
              <Form.Group style={{ width: 300 }} controlId="validationCustom01">
                <Form.Label>Name *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Form.Control.Feedback className="feedbackTxt">
                  Looks good!
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group style={{ width: 300 }} controlId="validationCustom02">
                <Form.Label>Contact No. *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <Form.Control.Feedback className="feedbackTxt">
                  Looks good!
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                style={{ width: 300 }}
                md="5"
                controlId="validationCustomUsername"
              >
                <Form.Label>Email Address *</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    placeholder="name@example.com"
                    aria-describedby="inputGroupPrepend"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group
                style={{ width: 300 }}
                md="5"
                controlId="validationCustom03"
              >
                <Form.Label>How can we help ?</Form.Label>

                <Form.Control
                  as="select"
                  value={help}
                  custom
                  onChange={(e) => setHelp(e.target.value)}
                >
                  <option></option>
                  <option>Web Development</option>
                  <option>App Development</option>
                  <option>Digital Marketing</option>
                  <option>Hosting</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            className="submitBtn"
            onClick={PostConsultation}
          >
            Submit form
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BookFreeConsultation;
