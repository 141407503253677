import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import ScrollTop from './components/ScrollToTop'
import { Fragment } from 'react';

ReactDOM.render(
  <BrowserRouter>
    <Fragment>
      <ScrollTop/>
      <App />
    </Fragment>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
