import React, { useState, useEffect } from "react";
import { Form, Modal, Button, FormControl } from "react-bootstrap";
import "./ContactUs.css";
import Footer from "../Footer/index";
import Sidebar from "../Sidebar/index";
import Navbar from "../Navbar/index";
import CheckedTick from "../../images/checked.gif";
import ReactPixel from "react-facebook-pixel";

function ContactUs() {
  const [isOpen, setIsOpen] = useState(false);
  const [help, setHelp] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [budget, setBudget] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [responseType, setResponseType] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  const successAlert = () => (
    <Modal
      size="sm"
      show={smShow}
      onHide={() => setSmShow(false)}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Body closeButton>
        <strong style={{ textAlign: "center" }}>
          Thank you for contacting us, we will get back to you soon!
        </strong>
        <div className="successgif">
          <img src={CheckedTick} alt="loading..." width="200" height="200" />
        </div>
      </Modal.Body>
    </Modal>
  );

  const PostContactUs = (e) => {
    e.preventDefault();
    fetch("/contactusform", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        help,
        name,
        company,
        email,
        phone,
        budget,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setSubmitted(true);
        if (data.error) {
          setValidated(true);
          setHelp("");
          setName("");
          setPhone("");
          setCompany("");
          setEmail("");
          setBudget("");
        } else {
          setSmShow(true);
          setResponseType("success");
          setHelp("");
          setName("");
          setPhone("");
          setCompany("");
          setEmail("");
          setBudget("");
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const advancedMatching = { em: "madhav.mishra@yodiso.com" };
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(277473760122474, advancedMatching, options);
    ReactPixel.pageView();
  }, []);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      {submitted && (responseType === "error" ? "" : successAlert())}
      <h2 className="headertag">Contact Us</h2>
      <div className="contactContainer">
        <section className="contact_wrapper">
          <div className="contact_info">
            <ul className="icons_wrapp">
              <li>
                <div className="icon">
                  <span className="material-icons-outlined"> place </span>
                </div>
                <div className="text" style={{ marginBottom: 10 }}>
                  19th Floor, Kamdhenu Business Center,
                  Kharghar, Navi Mumbai, Mumbai, Maharashtra-410210
                </div>
              </li>
              <li>
                <div className="icon">
                  <span className="material-icons-outlined">
                    {" "}
                    mail_outline{" "}
                  </span>
                </div>
                <div className="text" style={{ marginBottom: 10 }}>
                  contact@yodiso.com
                </div>
              </li>
              <li>
                <div className="icon">
                  <span className="material-icons-outlined">
                    {" "}
                    phone_in_talk{" "}
                  </span>
                </div>
                <div className="text" style={{ marginBottom: 10 }}>
                  +91-9137511786
                </div>
              </li>
            </ul>
          </div>

          <div className="contact_msg">
            <h3 className="title">Send a Message</h3>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 100,
              }}
            >
              <FormControl
                placeholder="Name *"
                aria-label="name"
                value={name}
                className="input-field"
                onChange={(e) => setName(e.target.value)}
                aria-describedby="basic-addon1"
              />
              <FormControl
                placeholder="Contact No. *"
                aria-label="contact"
                value={phone}
                className="input-field"
                onChange={(e) => setPhone(e.target.value)}
                aria-describedby="basic-addon1"
              />
              <FormControl
                placeholder="Email Address *"
                aria-label="mail"
                value={email}
                className="input-field"
                onChange={(e) => setEmail(e.target.value)}
                aria-describedby="basic-addon1"
              />
              <FormControl
                placeholder="Company *"
                aria-label="company"
                value={company}
                className="input-field"
                onChange={(e) => setCompany(e.target.value)}
                aria-describedby="basic-addon1"
              />
              <label htmlFor="basic-url">How can we help ?</label>
              <Form.Control
                as="select"
                value={help}
                className="input-field"
                onChange={(e) => setHelp(e.target.value)}
              >
                <option></option>
                <option>Web Development</option>
                <option>App Development</option>
                <option>Digital Marketing</option>
                <option>Hosting</option>
                <option>Error Solving</option>
              </Form.Control>
              <label htmlFor="basic-url">Budget</label>
              <Form.Control
                as="select"
                value={budget}
                className="input-field"
                onChange={(e) => setBudget(e.target.value)}
              >
                <option></option>
                <option>Below 30K</option>
                <option>INR 30,000 to INR 80,000</option>
                <option>INR 80,000 to INR 150,000</option>
                <option>INR 150,000 to INR 250,000</option>
                <option>Above 250,000</option>
              </Form.Control>

              <Button
                variant="primary"
                className="input-field"
                style={{ marginTop: 10 }}
                onClick={PostContactUs}
              >
                Send Message
              </Button>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default ContactUs;
