import React from "react";
import {
  ValueContainer,
  Heading,
  MainWrapper,
  ValueWrapper,
  Img,
  SubHeading,
  Subtitle,
  MainSubWrapper,
} from "./ValueElements";
import CustomerFocus from '../../images/customerfocus.svg'
import EmployeeEmpower from "../../images/employeeempower.svg";
import Honest from "../../images/honest.svg";
import InnovativeIdea from "../../images/ideas.svg";

const Values = () => {
  return (
    <>
      <ValueContainer>
        <Heading>Our Values</Heading>
        <MainWrapper>
          <MainSubWrapper>
            <ValueWrapper>
              <Img src={CustomerFocus} />
              <SubHeading>Customer Focused</SubHeading>
              <Subtitle>
                We are driven by customers and they are our #1 Priority. We are
                focused on what they need.
              </Subtitle>
            </ValueWrapper>
            <ValueWrapper>
              <Img src={EmployeeEmpower} />
              <SubHeading>Employee Empowerment</SubHeading>
              <Subtitle>
                We empower our employees with the necessary tools and resources
                to deliver the best-in-class services.
              </Subtitle>
            </ValueWrapper>
          </MainSubWrapper>

          <MainSubWrapper>
            <ValueWrapper>
              <Img src={Honest} />
              <SubHeading>Open & Honest</SubHeading>
              <Subtitle>
                Yodiso is an open and highly honest team of developers,
                Designers and Digital Marketers who know what our clients need.
              </Subtitle>
            </ValueWrapper>
            <ValueWrapper>
              <Img src={InnovativeIdea} />
              <SubHeading>Innovative Idea</SubHeading>
              <Subtitle>
                Our Solutions are based on innovative and creative ideas
                delivered by our expert team into their respective fields.
              </Subtitle>
            </ValueWrapper>
          </MainSubWrapper>
        </MainWrapper>
      </ValueContainer>
    </>
  );
};

export default Values;
