import React, { useState } from "react";
import { NavDropdown, Dropdown, Button } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import "./SidebarElements.css";
import Icon1 from "../../images/time.svg";
import { HashLink } from "react-router-hash-link";

const Sidebar = ({ isOpen, toggle }) => {
  

  return (
    <aside
      className="SidebarContainer"
      isOpen={isOpen}
      style={{
        opacity: isOpen ? "100%" : 0,
        top: isOpen ? 0 : "-100%",
      }}
    >
      <div className="Icon">
        <FaTimes onClick={toggle} className="CloseIcon" />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: 200,
          alignSelf: "center",
          marginLeft: 100,
          marginTop: -30,
        }}
      >
        <Button variant="success">
          <a href="/" style={{ color: "#fff" }}>
            Home
          </a>
        </Button>
        <Button variant="success" style={{ marginTop: 10}}>
          <HashLink to="/#about" style={{color:'white'}}>
            About
          </HashLink>
        </Button>
        <Dropdown>
          <Dropdown.Toggle
            style={{ marginTop: 10, width: 200 }}
            variant="success"
            id="dropdown-basic"
          >
            Web Services
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="/webdesign">Website Designing</Dropdown.Item>
            <Dropdown.Item href="/webdevelopment">
              Website Development
            </Dropdown.Item>
            <Dropdown.Item href="/webmaintenance">
              Website Maintenance
            </Dropdown.Item>
            <Dropdown.Item href="/webhosting">Website Hosting</Dropdown.Item>
            <Dropdown.Item href="/webapplication">
              Web Application
            </Dropdown.Item>
            <Dropdown.Item href="/customsoftware">
              Custom Software Solutions
            </Dropdown.Item>
            <Dropdown.Item href="/wordpressdev">
              WordPress Development
            </Dropdown.Item>
            <Dropdown.Item href="/androidappdev">
              Android App Development
            </Dropdown.Item>
            <Dropdown.Item href="/iosappdev">iOS App Development</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle
            style={{ marginTop: 10, width: 200 }}
            variant="success"
            id="dropdown-basic"
          >
            E-commerce Solutions
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="/ecomwebsitedev">
              Ecommerce Website Development
            </Dropdown.Item>
            <Dropdown.Item href="/ecomwebsitehosting">
              Ecommerce Web Hosting
            </Dropdown.Item>
            <Dropdown.Item href="/ecomseoservice">
              Ecommerce SEO Services
            </Dropdown.Item>
            <Dropdown.Item href="/ecomwebsitemaintenance">
              Ecommerce Website Maintenance
            </Dropdown.Item>
            <Dropdown.Item href="/ecompaymentgateway">
              Payment Gateway Integration
            </Dropdown.Item>
            <Dropdown.Item href="/ecomsslcertificate">
              SSL Certificate
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle
            style={{ marginTop: 10, width: 200 }}
            variant="success"
            id="dropdown-basic"
          >
            Marketing Services
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="/performancemarketing">
              Performance Marketing
            </Dropdown.Item>
            <Dropdown.Item href="/facebookmarketing">
              Facebook Marketing
            </Dropdown.Item>
            <Dropdown.Item href="/socialmediamarketing">
              Social Media Marketing
            </Dropdown.Item>
            <Dropdown.Item href="/payperclick">
              Pay Per Click - PPC
            </Dropdown.Item>
            <Dropdown.Item href="/socialmediapagemanagement">
              Social Media Page Management
            </Dropdown.Item>
            <Dropdown.Item href="/socialmediaoptimization">
              Social Media Optimization
            </Dropdown.Item>
            <Dropdown.Item href="/googlead">Google Ad</Dropdown.Item>
            <Dropdown.Item href="/emailmarketing">
              Email Marketing
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Button variant="success" style={{ marginTop: 10 }}>
          <a href="/career" style={{ color: "#fff" }}>
            Career
          </a>
        </Button>
        <Button variant="success" style={{ marginTop: 10 }}>
          <a href="/contactus" style={{ color: "#fff" }}>
            Contact Us
          </a>
        </Button>
      </div>
    </aside>
  );
};

export default Sidebar;
