export const homeObjOne = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Yodiso Company",
  headline: "Web App Development and Digital Marketing Agency",
  description:
    "At Yodiso, we all work together on a single mission and that is, “work for 100% client satisfaction”. Whether you need a website or a custom web application or a mobile application or any tech development for your business our passionate team is ready to help you provide a custom solution as per your requirements. We have a dedicated team of digital marketing to help master your brand online and improve your overall business growth.",
  buttonLabel: "See More",
  imgStart: false,
  img: require("../../images/side-image.png").default,
  alt: "aboutimage",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Why Choose Us",
  headline: "Digital Marketing and Web Development",
  description:
    "Our clients have been choosing Yodiso for a number of reasons including reliability, latest technologies, and constant updates & support. Read more about other advantages below.",
  buttonLabel: "Learn More",
  imgStart: true,
  img: require("../../images/market.png").default,
  alt: "Car",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: "services",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Yodiso Company",
  headline: "Digital Marketing and Web Development",
  description:
    "YODISO provide end-to-end marketing solution to master your brand online and improve your business presence.",
  buttonLabel: "Get started",
  imgStart: false,
  img: require("../../images/market.png").default,
  alt: "Car",
  dark: true,
  primary: true,
  darkText: false,
};
