import React, { useState,useEffect} from "react";
import Navbar from "../../Navbar/index";
import Webbanner from "../../../images/bannerimg.png";
import WebDesign from "../../../images/webdesignimg2.png";
import Testimonial from "../../Testimonial/Testimonial";
import WebApplication from "../../../images/webapplication.svg";
import EcommerceSol from "../../../images/ecommercesol.svg";
import SocialMedia from "../../../images/socialmedia.svg";
import MobileApp from "../../../images/mobileapp.svg";
import Footer from "../../Footer/index";
import "./WebsiteDesign.css";
import Sidebar from "../../Sidebar/index";
import WebDeveloper1 from "../../../images/webdevimg1.png";
import Button from "react-bootstrap/Button";
import BookFreeConsultation from "../../BookFreeConsultation/BookFreeConsultation";
import { Helmet } from "react-helmet";
import Enquiry from "../../EnquiryForm/Enquiry";
import ReactPixel from "react-facebook-pixel";

const cardOne = [
  {
    image: WebApplication,
    name: "Search Engine Optimization",
    link: "/ecomseoservice",
    desc:
      "Our SEO specialists are committed to creating better evolving strategies in-line with the latest search engine guidelines to maintain your website's ranking.",
  },
  {
    image: EcommerceSol,
    name: "Digital Marketing",
    link: "/socialmediamarketing",
    desc:
      "Digital marketing gives you infinite opportunities to grow your business. We have an expert digital marketing team to help you gain maximum out of your digital marketing.",
  },
  {
    image: EcommerceSol,
    name: "Google Ad",
    link: "/googlead",
    desc:
      "We help you manage your business google ads campaign more efficiently and effectively by leveraging our team of google ads experts.",
  },
];

const cardTwo = [
  {
    image: SocialMedia,
    name: "Lead Generation",
    link: "/performancemarketing",
    desc:
      "We are specialized in lead generation digital marketing, not only that but we also help you receive the lead in real-time So that you can immediately convert the lead to customer.",
  },
  {
    image: MobileApp,
    name: "Social Media Marketing",
    link: "/socialmediamarketing",
    desc:
      "Your business needs an experienced and expert digital marketing agency to ensure your business growth and handle all your social media channels.",
  },
  {
    image: MobileApp,
    name: "Email Marketing",
    link: "/emailmarketing",
    desc:
      "Email marketing has the highest ROI as compared to any other marketing. Yodiso will provide the best email marketing and campaign solution for your business.",
  },
];

function WebSiteDesign() {
  const [isOpen, setIsOpen] = useState(false);
  const [handlemodal, setHandleModal] = useState(false);



  const handleClose = () => {
    setHandleModal(false);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const advancedMatching = { em: "madhav.mishra@yodiso.com" };
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(277473760122474, advancedMatching, options);
    ReactPixel.pageView();
  }, []);
  return (
    <div style={{ height: 2000 }} style={{ scrollbarWidth: "none" }}>
      <Helmet>
        <title>YODISO | WEBSITE DESIGNING</title>
      </Helmet>
      <Enquiry />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <div className="navpagecontainer">
        <img src={Webbanner} className="webbanner" />

        <div className="top-leftt"></div>
        <h2 className="centeredd">
          Responsive Design | Easy Navigation | Stunning Look | Quick Load Time
        </h2>
      </div>

      <div style={{ textAlign: "center", marginTop: 80 }}>
        <h1 className="servicesHeading">
          Website <span style={{ color: "blue" }}>Designing</span>
        </h1>
      </div>

      <div className="briefContainerOne">
        <div>
          <img src={WebDeveloper1} className="imagewebdesignone" />
        </div>
        <div className="briefingwebdesignone">
          <h3 className="headerwebdesignone">
            Why Should You Hire Yodiso For Website Design?
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            While many content management systems available require zero
            technical knowledge to develop the website, we could bet many of
            so-called Website Designing Agency or rather we say best Website
            Designing Agency in their respective location, they are using the
            same content management system and name themselves as technical
            experts.
          </p>
          <p style={{ marginTop: 20, textAlign: "justify" }}>
            Having so much confusion around us among ourselves, choosing the
            right website designer and developers are very challenging for the
            business. We believe that an easy and short-cut way is a long term
            expensive affair and a non-scalable development. Having such ethics
            in mind we keep our team always focused on developing a scalable
            website that can be customized as per the business requirement and
            can be developed at any scale which might require in the future.
          </p>
        </div>
      </div>

      <div className="briefContainerTwo">
        <div className="briefingwebdesigntwo">
          <h3 className="headerwebdesigntwo">
            We Have Expert Team to Develop Your Website
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Developing and designing a scalable and interactive user-interface
            website is not a simple task, it requires deep technological and
            designing knowledge to execute that. If you are trying to find a
            unique, innovative, user-friendly and responsive Website Designing
            agency for your business then you should hire a professional and
            experienced website designer. We at Yodiso are known for developing
            a cutting-edge website best suited for your business.
          </p>
          <p style={{ marginTop: 20, textAlign: "justify" }}>
            We have no right to claim ourselves as one of the top website
            designing agency services providers, but we always strive to become
            the top website designing agency service providers, and we are proud
            to tell you that, in a short span of time we became one of the most
            popular agency in website designing and development.
          </p>
          <p style={{ marginTop: 20, textAlign: "justify" }}>
            We are known for providing cutting-edge website designing services
            by using the latest technology and trendy design best fit for your
            business.
          </p>
        </div>

        <div>
          <img src={WebDesign} className="imagewebdesigntwo" />
        </div>
      </div>
      <BookFreeConsultation showmodal={handlemodal} closemodal={handleClose} />
      <div className="serviceBtn">
        <Button
          className="serviceButton"
          variant="primary"
          onClick={() => setHandleModal(true)}
        >
          Book Free Consultation
        </Button>
      </div>

      <Testimonial/>

      <h3 className="additionalServiceHeading">
        Additional Services to scale your business
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          {cardOne.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
        <div className="cardLayoutAdditional">
          {cardTwo.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default WebSiteDesign;
