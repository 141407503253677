import React, { useState,useEffect } from "react";
import Navbar from "../../Navbar/index";
import Webbanner from "../../../images/bannerimg.png";
import Testimonial from "../../Testimonial/Testimonial";
import WebApplication from "../../../images/webapplication.svg";
import EcommerceSol from "../../../images/ecommercesol.svg";
import SocialMedia from "../../../images/socialmedia.svg";
import MobileApp from "../../../images/mobileapp.svg";
import Footer from "../../Footer/index";
import "./WebsiteDevelop.css";
import Sidebar from "../../Sidebar/index";
import WebDeveloper1 from "../../../images/webdevimg1.png";
import WebDeveloper2 from "../../../images/webdevimg2.png";
import Button from "react-bootstrap/Button";
import BookFreeConsultation from "../../BookFreeConsultation/BookFreeConsultation";
import { Helmet } from "react-helmet";
import Enquiry from "../../EnquiryForm/Enquiry";
import ReactPixel from "react-facebook-pixel";

const cardOne = [
  {
    image: WebApplication,
    name: "Search Engine Optimization",
    link: "/ecomseoservice",
    desc:
      "Our SEO specialists are committed to creating better evolving strategies in-line with the latest search engine guidelines to maintain your website's ranking.",
  },
  {
    image: EcommerceSol,
    name: "Digital Marketing",
    link: "/socialmediamarketing",
    desc:
      "Digital marketing gives you infinite opportunities to grow your business. We have an expert digital marketing team to help you gain maximum out of your digital marketing.",
  },
  {
    image: EcommerceSol,
    name: "Google Ad",
    link: "/googlead",
    desc:
      "We help you manage your business google ads campaign more efficiently and effectively by leveraging our team of google ads experts.",
  },
];

const cardTwo = [
  {
    image: SocialMedia,
    name: "Lead Generation",
    link: "/performancemarketing",
    desc:
      "We are specialized in lead generation digital marketing, not only that but we also help you receive the lead in real-time So that you can immediately convert the lead to customer.",
  },
  {
    image: MobileApp,
    name: "Social Media Marketing",
    link: "/socialmediamarketing",
    desc:
      "Your business needs an experienced and expert digital marketing agency to ensure your business growth and handle all your social media channels.",
  },
  {
    image: MobileApp,
    name: "Email Marketing",
    link: "/emailmarketing",
    desc:
      "Email marketing has the highest ROI as compared to any other marketing. Yodiso will provide the best email marketing and campaign solution for your business.",
  },
];


function WebsiteDevelop() {
  const [isOpen, setIsOpen] = useState(false);
  const [handlemodal, setHandleModal] = useState(false);

  const handleClose = () => {
    setHandleModal(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const advancedMatching = { em: "madhav.mishra@yodiso.com" };
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(277473760122474, advancedMatching, options);
    ReactPixel.pageView();
  }, []);
  return (
    <div style={{ height: 2000 }} style={{ scrollbarWidth: "none" }}>
      <Helmet>
        <title>YODISO | WEB DEVELOPMENT</title>
      </Helmet>
      <Enquiry/>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <div className="navpagecontainer">
        <img src={Webbanner} className="webbanner" />

        <div className="top-left"></div>
        <h2 className="centered">
          Latest Technology | User-Friendly | Low Maintenance
        </h2>
      </div>

      <div style={{ textAlign: "center", marginTop: 80 }}>
        <h1 className="servicesHeading">
          Website <span style={{ color: "blue" }}>Development</span>
        </h1>
      </div>

      <div className="briefContainerOne">
        <div>
          <img src={WebDeveloper1} className="imagewebsitedevone" />
        </div>
        <div className="briefingwebsitedevone">
          <h3 className="headerwebsitedevone">
            Why you need a business website?
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Website development is the foundation of your online presence, it
            reflects your brand image and digitally interacts with your
            customers and website visitors. Having a business website can help
            the brand reach a larger audience within a short span of time.
            Consumer behavior has changed over a period of time, they are
            adhering to the evolving technologies, therefore, it becomes a
            necessity to have a business website as your customer expects you to
            have your business website to find more information about your
            business. Same time your website can also help you generate business
            enquiries, Increase Brand Recall, Promote Goodwill to your customer
            and deliver strong marketing messages to your target audiences.
          </p>
        </div>
      </div>

      <div className="briefContainerTwo">
        <div className="briefingwebsitedevtwo">
          <h3 className="headerwebsitedevtwo">
            Why Choose Yodiso Website Development?
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Yodiso is one of the most reliable Website Development company that
            has committed to 100% client satisfaction. We take a client and the
            user-based approach to develop a website, we are a team of well
            professionals and experts in website development and designing. For
            any scale of web development be it small, medium or large we use the
            latest technology and trendy design.
          </p>
        </div>

        <div>
          <img src={WebDeveloper2} className="imagewebsitedevtwo" />
        </div>
      </div>
      <BookFreeConsultation showmodal={handlemodal} closemodal={handleClose} />
      <div className="serviceBtn">
        <Button
          className="serviceButton"
          variant="primary"
          onClick={() => setHandleModal(true)}
        >
          Book Free Consultation
        </Button>
      </div>

      <Testimonial />

      <h3 className="additionalServiceHeading">
        Additional Services to scale your business
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          {cardOne.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
        <div className="cardLayoutAdditional">
          {cardTwo.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default WebsiteDevelop;
