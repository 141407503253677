import React from 'react'

import TechSupport from "../../images/techdeveloper.svg";
import DigitalMarketing from "../../images/digitalmarketing.svg";
import RealTime from "../../images/realtimesupport.svg";
import {
  ServicesContainer,
  ServicesH1,
  ServicesH2,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesP,
} from "./ServiceElements";

const Services = () => {
    return (
      <ServicesContainer id="services">
        <ServicesH1>Our Services</ServicesH1>
        <ServicesWrapper>
          <ServicesCard>
            <ServicesIcon src={TechSupport} />
            <ServicesH2>Tech Development</ServicesH2>
            <ServicesP>
              Seatback and relax, the tech is our work. We provide end-to-end
              tech development and support for your business need!
            </ServicesP>
          </ServicesCard>

          <ServicesCard>
            <ServicesIcon src={DigitalMarketing} />
            <ServicesH2>Digital Marketing</ServicesH2>
            <ServicesP>
              Each of our marketers are focused to work on multiplying your
              business growth. We believe in performance marketing with high
              ROI.
            </ServicesP>
          </ServicesCard>

          <ServicesCard>
            <ServicesIcon src={RealTime} />
            <ServicesH2>Real-Time 24X7 Support</ServicesH2>
            <ServicesP>
              We are available 24x7 for your help. Have any technical or
              non-technical question just reach out to us.
            </ServicesP>
          </ServicesCard>
        </ServicesWrapper>

        {/* <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Click me!
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>Hello! I'm the body</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Click me!
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>Hello! I'm another body</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion> */}
      </ServicesContainer>
    );
}

export default Services
