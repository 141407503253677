import React, { useState,useEffect} from "react";
import Navbar from "../../Navbar/index";
import Performancebanner from "../../../images/Performance-Marketing-Header.png";
import ReactPixel from "react-facebook-pixel";
import Testimonial from "../../Testimonial/Testimonial";
import WebApplication from "../../../images/webapplication.svg";
import EcommerceSol from "../../../images/ecommercesol.svg";
import SocialMedia from "../../../images/socialmedia.svg";
import MobileApp from "../../../images/mobileapp.svg";
import Footer from "../../Footer/index";
import "./PerformanceMarketing.css";
import Sidebar from "../../Sidebar/index";
import PerformanceMarketingImg1 from "../../../images/Performance-mareting-img1.png";
import PerformanceMarketingImg2 from "../../../images/Performance-mareting-img2.png";
import Button from "react-bootstrap/Button";
import BookFreeConsultation from "../../BookFreeConsultation/BookFreeConsultation";
import { Helmet } from "react-helmet";
import Enquiry from "../../EnquiryForm/Enquiry";


const cardOne = [
  {
    image: WebApplication,
    name: "Search Engine Optimization",
    link: "/ecomseoservice",
    desc:
      "Our SEO specialists are committed to creating better evolving strategies in-line with the latest search engine guidelines to maintain your website's ranking.",
  },
  {
    image: EcommerceSol,
    name: "Digital Marketing",
    link: "/socialmediamarketing",
    desc:
      "Digital marketing gives you infinite opportunities to grow your business. We have an expert digital marketing team to help you gain maximum out of your digital marketing.",
  },
  {
    image: EcommerceSol,
    name: "Google Ad",
    link: "/googlead",
    desc:
      "We help you manage your business google ads campaign more efficiently and effectively by leveraging our team of google ads experts.",
  },
];

const cardTwo = [
  {
    image: SocialMedia,
    name: "Lead Generation",
    link: "/performancemarketing",
    desc:
      "We are specialized in lead generation digital marketing, not only that but we also help you receive the lead in real-time So that you can immediately convert the lead to customer.",
  },
  {
    image: MobileApp,
    name: "Social Media Marketing",
    link: "/socialmediamarketing",
    desc:
      "Your business needs an experienced and expert digital marketing agency to ensure your business growth and handle all your social media channels.",
  },
  {
    image: MobileApp,
    name: "Email Marketing",
    link: "/emailmarketing",
    desc:
      "Email marketing has the highest ROI as compared to any other marketing. Yodiso will provide the best email marketing and campaign solution for your business.",
  },
];

function PerformanceMarketing() {


  const [isOpen, setIsOpen] = useState(false);
  const [handlemodal, setHandleModal] = useState(false);

  const handleClose = () => {
    setHandleModal(false);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };
    useEffect(() => {
      const advancedMatching = { em: "madhav.mishra@yodiso.com" };
      const options = {
        autoConfig: true,
        debug: false,
      };
      ReactPixel.init(277473760122474, advancedMatching, options);
      ReactPixel.pageView();
    }, []);
  return (
    <div style={{ height: 2000 }} style={{ scrollbarWidth: "none" }}>
      <Helmet>
        <title>YODISO | PERFORMANCE MARKETING</title>
      </Helmet>
      <Enquiry/>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <div className="navpagecontainer">
        <img src={Performancebanner} className="webbanner" />

        <div className="top-left"></div>
        <h2 className="centered">
          Latest Technology | User-Friendly | Low Maintenance
        </h2>
      </div>

      <div style={{ textAlign: "center", marginTop: 80 }}>
        <h1 className="servicesHeading">
          Performance <span style={{ color: "blue" }}>Marketing</span>
        </h1>
      </div>

      <div className="briefContainerOne">
        <div>
          <img src={PerformanceMarketingImg1} className="imageperformanceone" />
        </div>
        <div className="briefingperformanceone">
          <h3 className="headerperformanceone">
            Yodiso Believes in Revenue Generation
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            For Yodiso, Performance Marketing is not just terminology but we
            believe that performance marketing should be focused on the
            company's overall growth and generate revenue for the business.
            Performance marketing is not catered to single-channel marketing but
            goes to all over the digital platforms and generates revenue out of
            the marketing campaign. Yodiso is one of the most trusted
            performance marketing partners for many businesses and brings their
            overall business growth. Yodiso has provided multifold Returns of
            Investment to many businesses.
          </p>
        </div>
      </div>

      <div className="briefContainerTwo">
        <div className="briefingperformancetwo">
          <h3 className="headerperformancetwo">
            Your Business Goal Our Commitment
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Yodiso sticks with your business goal and runs the marketing
            campaign to various channels in alignment to reach your business
            goal. Yodiso works collaboratively with businesses while keeping big
            pictures of the overall digital marketing program align with your
            business goal. We ensure you achieve multifold Returns of Your Ad
            Spend. We handle all implementation of the performance marketing and
            optimize it to drive the result and bring the revenue for your
            business.
          </p>
        </div>

        <div>
          <img src={PerformanceMarketingImg2} className="imageperformancetwo" />
        </div>
      </div>
      <BookFreeConsultation showmodal={handlemodal} closemodal={handleClose} />
      <div className="serviceBtn">
        <Button
          className="serviceButton"
          variant="primary"
          onClick={() => setHandleModal(true)}
        >
          Book Free Consultation
        </Button>
      </div>
      <h3 className="additionalServiceHeading">Why Should you Hire Yodiso?</h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={WebApplication} />
            <p className="boxPara">Dedicate Manager to Handle your Account</p>
            <p className="boxSubPara">
              A dedicated manager will monitor your campaign performance and get
              it to optimize for the best result.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Transparent</p>
            <p className="boxSubPara">
              We believe that transparency is the key to build trust and a
              long-lasting client relationship. All marketing development will
              be transparent to you.
            </p>
          </div>

          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Real time update of performance</p>
            <p className="boxSubPara">
              You will have real-time visibility of our work around towards your
              performance marketing.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Real time lead transfer</p>
            <p className="boxSubPara">
              The windows of opportunity to convert a lead to a customer are
              very less therefore we send the real-time leads to you.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Marketing Dashboard</p>
            <p className="boxSubPara">
              Through our marketing dashboard, you will have the access to your
              consolidated leads and other marketing KPIs.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">High ROI</p>
            <p className="boxSubPara">
              Our performance marketing will help you gain multifold returns of
              marketing budgets and overall growth in your business.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">High Conversion Rate</p>
            <p className="boxSubPara">
              We have a good track record of the highest conversion ratio of our
              performance marketing through automatic conversion campaigns.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Low Customer acquisition cost</p>
            <p className="boxSubPara">
              Our center of development of your overall performance marketing is
              to reduce the customer acquisition cost.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Lead Generation</p>
            <p className="boxSubPara">
              Through our Lead Gen campaign, we generate low-cost leads for your
              business and send them real-time to you.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">High Customer Retention</p>
            <p className="boxSubPara">
              Customer retention is way-way more important than just acquiring
              new customers. A 5% increase in customer retention can increase
              25-95% of company revenue.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Customer Re-targeting</p>
            <p className="boxSubPara">
              Customer retargeting will help you reduce the customer acquisition
              cost hence it increases your profitability.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">24/7 Customer Support</p>
            <p className="boxSubPara">
              We are available 24/7 to help you address your query, feel free to
              reach out to us any time you want.
            </p>
          </div>
        </div>
      </div>
      <Testimonial />

      <h3 className="additionalServiceHeading">
        Additional Services to scale your business
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          {cardOne.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
        <div className="cardLayoutAdditional">
          {cardTwo.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PerformanceMarketing;
