import React from "react";
import Typist from "react-text-typist";
import "./Animation.css";

function Animation() {

  return (
    <div style={{ marginTop: 35, marginBottom: 10 }}>
      <Typist
        className="animation"
        
        sentences={[
          "Latest Technology & User-Friendly Design",
          "Responsive Design for All Devices",
          "SEO Services For Small And Large Business",
          "Website Redevelopment As Per Your Needs",
          "E-commerce Solution with Payment Gateway",
          "Dedicated Account Manager for Your Project",
          "Digital Marketing Solution to Grow Business",
          "All Types Of Custom Software Development",
          "One-Stop Solution For Tech & Marketing",
          "Real-Time 24/7 Customer Support"
        ]}
        loop={true}
      />
    </div>
  );
}

export default Animation;
