import React, { useState,useEffect } from "react";
import Navbar from "../../Navbar/index";
import PaymentGatewaybanner from "../../../images/Payment-Gateway-Header.png";
import ReactPixel from "react-facebook-pixel";
import Testimonial from "../../Testimonial/Testimonial";
import WebApplication from "../../../images/webapplication.svg";
import EcommerceSol from "../../../images/ecommercesol.svg";
import SocialMedia from "../../../images/socialmedia.svg";
import MobileApp from "../../../images/mobileapp.svg";
import Footer from "../../Footer/index";
import "./SSLCerti.css";
import Sidebar from "../../Sidebar/index";
import PaymentGatewayimg2 from "../../../images/paymentgatewayimg2.png";
import Button from "react-bootstrap/Button";
import BookFreeConsultation from "../../BookFreeConsultation/BookFreeConsultation";
import { Helmet } from "react-helmet";
import Enquiry from "../../EnquiryForm/Enquiry";

const cardOne = [
  {
    image: WebApplication,
    name: "Search Engine Optimization",
    link: "/ecomseoservice",
    desc:
      "Our SEO specialists are committed to creating better evolving strategies in-line with the latest search engine guidelines to maintain your website's ranking.",
  },
  {
    image: EcommerceSol,
    name: "Digital Marketing",
    link: "/socialmediamarketing",
    desc:
      "Digital marketing gives you infinite opportunities to grow your business. We have an expert digital marketing team to help you gain maximum out of your digital marketing.",
  },
  {
    image: EcommerceSol,
    name: "Google Ad",
    link: "/googlead",
    desc:
      "We help you manage your business google ads campaign more efficiently and effectively by leveraging our team of google ads experts.",
  },
];

const cardTwo = [
  {
    image: SocialMedia,
    name: "Lead Generation",
    link: "/performancemarketing",
    desc:
      "We are specialized in lead generation digital marketing, not only that but we also help you receive the lead in real-time So that you can immediately convert the lead to customer.",
  },
  {
    image: MobileApp,
    name: "Social Media Marketing",
    link: "/socialmediamarketing",
    desc:
      "Your business needs an experienced and expert digital marketing agency to ensure your business growth and handle all your social media channels.",
  },
  {
    image: MobileApp,
    name: "Email Marketing",
    link: "/emailmarketing",
    desc:
      "Email marketing has the highest ROI as compared to any other marketing. Yodiso will provide the best email marketing and campaign solution for your business.",
  },
];

function SSLCerti() {
  const [isOpen, setIsOpen] = useState(false);
  const [handlemodal, setHandleModal] = useState(false);

  const handleClose = () => {
    setHandleModal(false);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };
    useEffect(() => {
      const advancedMatching = { em: "madhav.mishra@yodiso.com" };
      const options = {
        autoConfig: true,
        debug: false,
      };
      ReactPixel.init(277473760122474, advancedMatching, options);
      ReactPixel.pageView();
    }, []);
  return (
    <div style={{ height: 2000 }} style={{ scrollbarWidth: "none" }}>
      <Helmet>
        <title>YODISO | SSL CERTIFICATION</title>
      </Helmet>
      <Enquiry/>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <div className="navpagecontainer">
        <img src={PaymentGatewaybanner} className="webbanner" />

        <div className="top-left"></div>
        <h2 className="centered">
          Latest Technology | User-Friendly | Low Maintenance
        </h2>
      </div>

      <div style={{ textAlign: "center", marginTop: 80 }}>
        <h1 className="servicesHeading">
          SSL <span style={{ color: "blue" }}>Certificate</span>
        </h1>
      </div>

      <div className="briefContainerOne">
        <div>
          <img src={PaymentGatewayimg2} className="imagesslone" />
        </div>
        <div className="briefingsslone">
          <h3 className="headersslone">
            Secure Your Website with SSL Certificate
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            The core functionality of an SSL certificate in your website is to
            protect your data from the hacker. SSL certificate encrypts every
            bit of data that has been communicated to your server and clients.
            In the Layman language, we can say the SSL certificate locks all the
            data in your websites and can only be unlocked by the intended
            recipient.
          </p>
        </div>
      </div>

      <h3 className="additionalServiceHeading">Benefits of SSL Certificate</h3>
      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={WebApplication} />
            <p className="boxPara">SSL Protect the Website Data</p>
            <p className="boxSubPara">
              Our SEO specialists are committed to creating better evolving
              strategies in-line with the latest search engine guidelines to
              maintain your website's ranking.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">SSL Proclaim Your Website Identity</p>
            <p className="boxSubPara">
              Digital marketing gives you infinite opportunities to grow your
              business. We have an expert digital marketing team to help you
              gain maximum out of your digital marketing.
            </p>
          </div>

          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">SSL Help in Better Search Ranking</p>
            <p className="boxSubPara">
              We help you manage your business google ads campaign more
              efficiently and effectively by leveraging our team of google ads
              experts.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">
              SSL Helps You Satisfy PCI/DSS Requirements
            </p>
            <p className="boxSubPara">
              We are specialized in lead generation digital marketing, not only
              that but we also help you receive the lead in real-time So that
              you can immediately convert the lead to customer.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">SSL Improves Customer Trust</p>
            <p className="boxSubPara">
              Your business needs an experienced and expert digital marketing
              agency to ensure your business growth and handle all your social
              media channels.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">SSL Secure your customer payments</p>
            <p className="boxSubPara">
              Email marketing has the highest ROI as compared to any other
              marketing. Yodiso will provide the best email marketing and
              campaign solution for your business.
            </p>
          </div>
        </div>
      </div>

      <BookFreeConsultation showmodal={handlemodal} closemodal={handleClose} />
      <div className="serviceBtn">
        <Button
          className="serviceButton"
          variant="primary"
          onClick={() => setHandleModal(true)}
        >
          Book Free Consultation
        </Button>
      </div>

      <Testimonial />

      <h3 className="additionalServiceHeading">
        Additional Services to scale your business
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          {cardOne.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
        <div className="cardLayoutAdditional">
          {cardTwo.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default SSLCerti;
