import React from 'react'
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.min.css";
import './faq.css'


function faq() {
    return (
      <div style={{ paddingBottom: 30, paddingTop: 60 }}>
        <h4 style={{ textAlign: "center",fontWeight:'bold',marginBottom:40}}>
          Frequently Asked Questions
        </h4>
        <Accordion>
          <div className="faqcontainer">
            <div className="cardContainerFirst">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  How can I reach out to you?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    You can either submit your requirement by filling the
                    contact us form or you can directly call on: +91-9137511787
                    or your can write us your detailed requirements to
                    business@yodiso.com
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="cardOne">
                <Accordion.Toggle as={Card.Header} eventKey="1">
                  How to start after the Agreement signup?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    Congratulation! After the agreement, you will be assigned
                    with a dedicated Account Manager along with your
                    Relationship Manager and they will take you through the next
                    course of action.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="cardTwo">
                <Accordion.Toggle as={Card.Header} eventKey="2">
                  If I am not satisfied with the development can I ask for
                  change?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    Yes, all the development will be made live only after your
                    approval.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="cardThree">
                <Accordion.Toggle as={Card.Header} eventKey="3">
                  Can I ask for the changes in the development?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    Yes, you can ask for the changes in your project.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="cardFour">
                <Accordion.Toggle as={Card.Header} eventKey="4">
                  Can I ask for a refund if I am not satisfied?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    Yes, If you are not satisfied with our development or
                    services with a valid reason you can ask for a refund within
                    15 days of agreement.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>

            <div className="cardContainerSecond">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="5">
                  What is the timeline to receive the refund?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    Upon approval of the refund the amount will be credited
                    within 7 working days.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="cardSix">
                <Accordion.Toggle as={Card.Header} eventKey="6">
                  If I am not satisfied with services whom I can escalate to?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    Well, if you have any further query you can seek support
                    from our higher management by asking your Relationship
                    manager to connect you with or you can directly write your
                    query to escalation@yodiso.com.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="cardSeven">
                <Accordion.Toggle as={Card.Header} eventKey="7">
                  How soon I get a revert from the higher management?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="7">
                  <Card.Body>
                    As we understand the escalation should be addressed fast, we
                    won’t take more than 2 Hrs to revert you back.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="cardEight">
                <Accordion.Toggle as={Card.Header} eventKey="8">
                  If I need any technical help whom should I contact?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="8">
                  <Card.Body>
                    You can either connect with your relationship manager or
                    your account manager. They have full access to help you with
                    any technical support you might need.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="cardNine">
                <Accordion.Toggle as={Card.Header} eventKey="9">
                  How soon we can start after the agreement signed up?
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="9">
                  <Card.Body>
                    We take just 1 working day to assign you the account manager
                    and start working you’re your project.
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          </div>
        </Accordion>
      </div>
    );
}

export default faq
