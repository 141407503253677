import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 800px;
  margin-top: 80px;
  position: relative;
  z-index: 1;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient() (180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
  }

  @media screen and (max-width: 480px) {
    height: 1540px !important;
    margin-top: -280px;
    margin-left: -10px;
  }

  @media screen and (max-width: 1024px) {
    height: 900px;
  }

  @media screen and (max-width: 768px) {
    height: 1010px;
    margin-top: 40px;
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  height: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 500px;

  @media screen and (max-width: 480px) {
    position: relative;
    top: -20%;
  }
  @media screen and (max-width: 1024px) {
    margin-top: 320px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 350px;
  }
`;

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 35px;
    
  }
  @media screen and (max-width: 480px) {
    font-size: 23px;
    width:380px;
    margin-top:20px;
  }
`;

export const HeroP = styled.p`
  margin-top: 24px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  max-width: 900px;
  width:800px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
    width: 360px;
  }
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 480px) {
    margin-top: 25px;
  }
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const CardLayoutContainer = styled.div`
  display: flex;
  margin-bottom: 220px;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    margin-top:25px;
    margin-left:1px !important;
  }
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    font-size: 24px;
    margin-left:-10px
  }
`;


export const CardLayout = styled.div`
  display: flex;
  margin-bottom: 220px;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    margin-top:-10px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
`;
export const CardBox = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 250px;
  width: 300px;
  margin-left: 10px;
  max-height: 340px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  @media screen and (max-width: 480px) {
    height: 250px !important;
    width: 350px !important;
    margin-left: 1px;
    margin-top: 20px;
    margin-bottom:10px;
  }

  @media screen and (max-width: 1024px) {
    height: 200px ;
    width: 450px ;
    margin-left: 10px;
    margin-top: 7px;
  }

  @media screen and (max-width: 768px) {
    height: 250px;
    width: 370px;
    margin-left: 10px;
    margin-top: 1px;
  }
`;



export const ImgLogo = styled.img`
  margin: 0 0 10px 0;
  padding-right: 0;
  left: 2%;
  width: 70px;
  height: 50px;
`;

export const BoxPara = styled.p`
  color: #000;
  font-weight: bold;
  font-size: 16px;
  margin-top: 15px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 480px) {
    font-size: 19px;
    margin-top: 2px;
  }
`;
export const BoxSubPara = styled.p`
  color: #000;
  font-size: 16px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 480px) {
    font-size: 17px;
  }
`;
