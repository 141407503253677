import React, { useState,useEffect } from "react";
import Navbar from "../../Navbar/index";
import GoogleAdbanner from "../../../images/Google-Header-banner.png";
import Testimonial from "../../Testimonial/Testimonial";
import WebApplication from "../../../images/webapplication.svg";
import EcommerceSol from "../../../images/ecommercesol.svg";
import SocialMedia from "../../../images/socialmedia.svg";
import MobileApp from "../../../images/mobileapp.svg";
import Footer from "../../Footer/index";
import "./GoogleAd.css";
import Sidebar from "../../Sidebar/index";
import GoogleAdsImg1 from "../../../images/google-adsimg1.png";
import Button from "react-bootstrap/Button";
import BookFreeConsultation from "../../BookFreeConsultation/BookFreeConsultation";
import { Helmet } from "react-helmet";
import Enquiry from "../../EnquiryForm/Enquiry";
import ReactPixel from "react-facebook-pixel";

const cardOne = [
  {
    image: WebApplication,
    name: "Search Engine Optimization",
    link: "/ecomseoservice",
    desc:
      "Our SEO specialists are committed to creating better evolving strategies in-line with the latest search engine guidelines to maintain your website's ranking.",
  },
  {
    image: EcommerceSol,
    name: "Digital Marketing",
    link: "/socialmediamarketing",
    desc:
      "Digital marketing gives you infinite opportunities to grow your business. We have an expert digital marketing team to help you gain maximum out of your digital marketing.",
  },
  {
    image: EcommerceSol,
    name: "Google Ad",
    link: "/googlead",
    desc:
      "We help you manage your business google ads campaign more efficiently and effectively by leveraging our team of google ads experts.",
  },
];

const cardTwo = [
  {
    image: SocialMedia,
    name: "Lead Generation",
    link: "/performancemarketing",
    desc:
      "We are specialized in lead generation digital marketing, not only that but we also help you receive the lead in real-time So that you can immediately convert the lead to customer.",
  },
  {
    image: MobileApp,
    name: "Social Media Marketing",
    link: "/socialmediamarketing",
    desc:
      "Your business needs an experienced and expert digital marketing agency to ensure your business growth and handle all your social media channels.",
  },
  {
    image: MobileApp,
    name: "Email Marketing",
    link: "/emailmarketing",
    desc:
      "Email marketing has the highest ROI as compared to any other marketing. Yodiso will provide the best email marketing and campaign solution for your business.",
  },
];

function GoogleAd() {

  const [isOpen, setIsOpen] = useState(false);
  const [handlemodal, setHandleModal] = useState(false);

  const handleClose = () => {
    setHandleModal(false);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };
    useEffect(() => {
      const advancedMatching = { em: "madhav.mishra@yodiso.com" };
      const options = {
        autoConfig: true,
        debug: false,
      };
      ReactPixel.init(277473760122474, advancedMatching, options);
      ReactPixel.pageView();
    }, []);
  return (
    <div style={{ height: 2000 }} style={{ scrollbarWidth: "none" }}>
      <Helmet>
        <title>YODISO | GOOGLE ADS</title>
      </Helmet>
      <Enquiry/>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <div className="navpagecontainer">
        <img src={GoogleAdbanner} className="webbanner" />

        <div className="top-left"></div>
        <h2 className="centered">
          Latest Technology | User-Friendly | Low Maintenance
        </h2>
      </div>

      <div style={{ textAlign: "center", marginTop: 80 }}>
        <h1 className="servicesHeading">
          Google <span style={{ color: "blue" }}>Advertisement</span>
        </h1>
      </div>

      <div className="briefContainerOne">
        <div>
          <img src={GoogleAdsImg1} className="imagegoogleadone" />
        </div>
        <div className="briefinggoogleadone">
          <h3 className="headergoogleadone">
            Better Lead Quality With Google Ad
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Google Ads is one of the most reliable online advertising options
            which provide the best ROI. The advantage of running google ads is
            you only pay when someone clicks on your ads and gets redirected to
            your website. Yodiso is the best Google Ad agency and we are
            specialized in gaining the highest ROI from your Google ads.
          </p>
        </div>
      </div>

      <BookFreeConsultation showmodal={handlemodal} closemodal={handleClose} />
      <div className="serviceBtn">
        <Button
          className="serviceButton"
          variant="primary"
          onClick={() => setHandleModal(true)}
        >
          Book Free Consultation
        </Button>
      </div>

      <h3 className="additionalServiceHeading">
        Execution plan for Google Ads:
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={WebApplication} />
            <p className="boxPara">Understand your Business Goal</p>
            <p className="boxSubPara">
              We first understand your business goal and create the marketing
              plan of action to achieve your business goal.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Create the Google Ads KPI</p>
            <p className="boxSubPara">
              We set the KPIs benchmark before we run google ads and optimize
              the ads such that your business goal and marketing performance are
              aligned with each other.
            </p>
          </div>

          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Create a Stunning Landing page</p>
            <p className="boxSubPara">
              We crate fast-loading landing page according to your brand image
              and make it more personalized for customers with the right content
              and call to action.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">AdWords Account Creation</p>
            <p className="boxSubPara">
              Don’t have AdWords account? Don’t worry we will create an AdWords
              account for your business.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Google Ads Account Setup</p>
            <p className="boxSubPara">
              You just relax now, we will set-up your business Google ads
              account before we start the campaign.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Keywords Research</p>
            <p className="boxSubPara">
              We research and pick-up relevant keywords around your business and
              the customers that they are searching for and run your google
              advertisement.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Text Ads and Image Ad creation</p>
            <p className="boxSubPara">
              All the text ads contents and the design required for the image
              ads will be done by our expert teams.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Bid Management</p>
            <p className="boxSubPara">
              We have expert bid management team to minimize the cost per click
              on your google ads and other search ads.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Conversion Tracking</p>
            <p className="boxSubPara">
              We will setup the conversion tracking tools to track the
              conversion ratio out of our google ads.
            </p>
          </div>
        </div>
      </div>

      <Testimonial />

      <h3 className="additionalServiceHeading">
        Additional Services to scale your business
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          {cardOne.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
        <div className="cardLayoutAdditional">
          {cardTwo.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default GoogleAd;
