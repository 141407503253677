import React, { useState,useEffect } from "react";
import Navbar from "../../Navbar/index";
import SocialMediaPagebanner from "../../../images/Social-Media-page-Header.png";
import Testimonial from "../../Testimonial/Testimonial";
import WebApplication from "../../../images/webapplication.svg";
import EcommerceSol from "../../../images/ecommercesol.svg";
import SocialMedia from "../../../images/socialmedia.svg";
import MobileApp from "../../../images/mobileapp.svg";
import Footer from "../../Footer/index";
import "./SocialMediaPageManagement.css";
import Sidebar from "../../Sidebar/index";
import SocialMediaPageManageImg1 from "../../../images/SocialMediaPageManageimg1.png";
import SocialMediaPageManageImg2 from "../../../images/SocialMediaPageManageimg2.png";
import Button from "react-bootstrap/Button";
import BookFreeConsultation from "../../BookFreeConsultation/BookFreeConsultation";
import { Helmet } from "react-helmet";
import Enquiry from "../../EnquiryForm/Enquiry";
import ReactPixel from "react-facebook-pixel";


const cardOne = [
  {
    image: WebApplication,
    name: "Search Engine Optimization",
    link: "/ecomseoservice",
    desc:
      "Our SEO specialists are committed to creating better evolving strategies in-line with the latest search engine guidelines to maintain your website's ranking.",
  },
  {
    image: EcommerceSol,
    name: "Digital Marketing",
    link: "/socialmediamarketing",
    desc:
      "Digital marketing gives you infinite opportunities to grow your business. We have an expert digital marketing team to help you gain maximum out of your digital marketing.",
  },
  {
    image: EcommerceSol,
    name: "Google Ad",
    link: "/googlead",
    desc:
      "We help you manage your business google ads campaign more efficiently and effectively by leveraging our team of google ads experts.",
  },
];

const cardTwo = [
  {
    image: SocialMedia,
    name: "Lead Generation",
    link: "/performancemarketing",
    desc:
      "We are specialized in lead generation digital marketing, not only that but we also help you receive the lead in real-time So that you can immediately convert the lead to customer.",
  },
  {
    image: MobileApp,
    name: "Social Media Marketing",
    link: "/socialmediamarketing",
    desc:
      "Your business needs an experienced and expert digital marketing agency to ensure your business growth and handle all your social media channels.",
  },
  {
    image: MobileApp,
    name: "Email Marketing",
    link: "/emailmarketing",
    desc:
      "Email marketing has the highest ROI as compared to any other marketing. Yodiso will provide the best email marketing and campaign solution for your business.",
  },
];

function SocialMediaPageManagement() {
  const [isOpen, setIsOpen] = useState(false);
  const [handlemodal, setHandleModal] = useState(false);

  const handleClose = () => {
    setHandleModal(false);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };
   useEffect(() => {
     const advancedMatching = { em: "madhav.mishra@yodiso.com" };
     const options = {
       autoConfig: true,
       debug: false,
     };
     ReactPixel.init(277473760122474, advancedMatching, options);
     ReactPixel.pageView();
   }, []);
  return (
    <div style={{ height: 2000 }} style={{ scrollbarWidth: "none" }}>
      <Helmet>
        <title>YODISO | PAGE MANAGEMENT</title>
      </Helmet>
      <Enquiry/>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <div className="navpagecontainer">
        <img src={SocialMediaPagebanner} className="webbanner" />

        <div className="top-left"></div>
        <h2 className="centered">
          Latest Technology | User-Friendly | Low Maintenance
        </h2>
      </div>

      <div style={{ textAlign: "center", marginTop: 80 }}>
        <h1 className="servicesHeading">
          Social Media Page <span style={{ color: "blue" }}>Management</span>
        </h1>
      </div>

      <div className="briefContainerOne">
        <div>
          <img
            src={SocialMediaPageManageImg1}
            className="imagesocialmediapageone"
          />
        </div>
        <div className="briefingsocialmediapageone">
          <h3 className="headersocialmediapageone">You Must Need It</h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Social media page management or Online Reputation Management builds
            trust and credibility among your customers. Social media page
            management is the process of managing the online presence in the
            social media platforms like Facebook, Instagram, LinkedIn, Twitter,
            etc. By creating, Publishing and analyzing content you want to post.
            Managing social media is not just related to posting but it requires
            a business to regularly engage and interact with the social media
            users.
          </p>
        </div>
      </div>

      <div className="briefContainerTwo">
        <div className="briefingsocialmediapagetwo">
          <h3 className="headersocialmediapagetwo">
            Hire Yodiso’s Expert Social Media Manager
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Yodiso is one of the most trusted companies in managing social media
            pages for the different domains of business. We assign a dedicated
            team who works for managing your Facebook, Instagram, LinkedIn,
            Twitter and other social media channels. Our team includes a
            Designer, Social media marketer and content writer all together they
            will work to boost your business online presence. Before we post
            anything across all social media channels we take your approval on
            design and contents.
          </p>
        </div>

        <div>
          <img
            src={SocialMediaPageManageImg2}
            className="imagesocialmediapagetwo"
          />
        </div>
      </div>
      <BookFreeConsultation showmodal={handlemodal} closemodal={handleClose} />
      <div className="serviceBtn">
        <Button
          className="serviceButton"
          variant="primary"
          onClick={() => setHandleModal(true)}
        >
          Book Free Consultation
        </Button>
      </div>

      <h3 className="additionalServiceHeading">
        Benefits of Social Media Page Management:
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={WebApplication} />
            <p className="boxPara">Increase Brand Awareness</p>
            <p className="boxSubPara">
              Alone Facebook has 2.7 billion of active user and counting, the
              social media give you more exposure among those active users who
              are actively searching for the brand like yours.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Increase Website Traffic</p>
            <p className="boxSubPara">
              People want to read more about your brand, a regular social media
              post with relevant content can easily drive traffic to your
              websites.
            </p>
          </div>

          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Improve Search Engine Ranking</p>
            <p className="boxSubPara">
              Social media gives good exposure to the brand with link sharing it
              drives good traffic to the website and it also boosts local search
              engine ranking.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Higher Conversion rates</p>
            <p className="boxSubPara">
              A regular and periodic post on social media builds trust in
              customer's minds which leads to better conversion rates.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Better Customer Satisfaction</p>
            <p className="boxSubPara">
              Customers are more open to asking questions on social media,
              answering their queries gives them better customer satisfaction.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Improve brand Loyalty</p>
            <p className="boxSubPara">
              Customers see social media as a service channel too and they love
              to directly communicate with you through these channels.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Stay on Top of Customer’s Mind</p>
            <p className="boxSubPara">
              Through social media post engagement and engaging with other
              business-related content can keep your brand on top of the
              customer's mind.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Zero Cost</p>
            <p className="boxSubPara">
              Social media is completely free, and you don’t have to spend
              anything to create a profile, post & share content, and
              participate in any conversations.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Generate Lead</p>
            <p className="boxSubPara">
              Beyond the brand awareness and engagement, collecting leads on
              social media will help the brand find people interested in your
              services and product.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Boost Sales</p>
            <p className="boxSubPara">
              Through social media posts, we can generate more qualified leads
              and it boosts your overall sales growth.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Go Viral</p>
            <p className="boxSubPara">
              Social media has the potential to take your brand to a new level,
              it can make your business viral at any point in time.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Retarget Your Customer</p>
            <p className="boxSubPara">
              Social media allow us to precisely retarget our potential
              customers and easily upsell your product and services.
            </p>
          </div>
        </div>
      </div>

      <Testimonial />

      <h3 className="additionalServiceHeading">
        Additional Services to scale your business
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          {cardOne.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
        <div className="cardLayoutAdditional">
          {cardTwo.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default SocialMediaPageManagement;


