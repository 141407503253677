import React, { useState,useEffect } from "react";
import Navbar from "../../Navbar/index";
import Webbanner from "../../../images/webdev.jpg";
import WebDeveloper from "../../../images/webdeveloper.svg";
import Testimonial from "../../Testimonial/Testimonial";
import WebApplication from "../../../images/webapplication.svg";
import EcommerceSol from "../../../images/ecommercesol.svg";
import SocialMedia from "../../../images/socialmedia.svg";
import MobileApp from "../../../images/mobileapp.svg";
import Footer from "../../Footer/index";
import "./WebsiteMaintenance.css";
import Sidebar from "../../Sidebar/index";
import Button from "react-bootstrap/Button";
import WebDeveloper1 from "../../../images/webdevimg1.png";
import WebDeveloper2 from "../../../images/webdevimg2.png";
import BookFreeConsultation from "../../BookFreeConsultation/BookFreeConsultation";
import { Helmet } from "react-helmet";
import Enquiry from "../../EnquiryForm/Enquiry";
import ReactPixel from "react-facebook-pixel";


const cardOne = [
  {
    image: WebApplication,
    name: "Search Engine Optimization",
    link: "/ecomseoservice",
    desc:
      "Our SEO specialists are committed to creating better evolving strategies in-line with the latest search engine guidelines to maintain your website's ranking.",
  },
  {
    image: EcommerceSol,
    name: "Digital Marketing",
    link: "/socialmediamarketing",
    desc:
      "Digital marketing gives you infinite opportunities to grow your business. We have an expert digital marketing team to help you gain maximum out of your digital marketing.",
  },
  {
    image: EcommerceSol,
    name: "Google Ad",
    link: "/googlead",
    desc:
      "We help you manage your business google ads campaign more efficiently and effectively by leveraging our team of google ads experts.",
  },
];

const cardTwo = [
  {
    image: SocialMedia,
    name: "Lead Generation",
    link: "/performancemarketing",
    desc:
      "We are specialized in lead generation digital marketing, not only that but we also help you receive the lead in real-time So that you can immediately convert the lead to customer.",
  },
  {
    image: MobileApp,
    name: "Social Media Marketing",
    link: "/socialmediamarketing",
    desc:
      "Your business needs an experienced and expert digital marketing agency to ensure your business growth and handle all your social media channels.",
  },
  {
    image: MobileApp,
    name: "Email Marketing",
    link: "/emailmarketing",
    desc:
      "Email marketing has the highest ROI as compared to any other marketing. Yodiso will provide the best email marketing and campaign solution for your business.",
  },
];

function WebsiteMaintenance() {
  const [isOpen, setIsOpen] = useState(false);
  const [handlemodal, setHandleModal] = useState(false);

  
  const handleClose = () => {
    setHandleModal(false);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const advancedMatching = { em: "madhav.mishra@yodiso.com" };
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(277473760122474, advancedMatching, options);
    ReactPixel.pageView();
  }, []);
  return (
    <div style={{ height: 2000 }} style={{ scrollbarWidth: "none" }}>
      <Helmet>
        <title>YODISO | WEB MAINTENANCE</title>
      </Helmet>
      <Enquiry/>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <div className="navpagecontainer">
        <img src={Webbanner} className="webbanner" />

        <div className="top-left"></div>
        <h2 className="centered">
          Latest Technology | User-Friendly | Low Maintenance
        </h2>
      </div>

      <div style={{ textAlign: "center", marginTop: 80 }}>
        <h1 className="servicesHeading">
          Website <span style={{ color: "blue" }}>Maintenance</span>
        </h1>
      </div>

      <div className="briefContainerOne">
        <div>
          <img src={WebDeveloper1} className="imagewebmaintenanceone" />
        </div>
        <div className="briefingwebmaintenanceone">
          <h3 className="headerwebmaintenanceone">
            Why is Maintenance Important?
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Maintenance is more important than development, this is valid
            everywhere including your car and the same goes with your websites.
            Simply having a business website doesn’t serve your purpose,
            maintenance is more important for a website to run at its full
            capacity. Properly maintaining your site helps security
            improvements, increases the number of new visitors, retains the
            website visitor, improves the website browsing experience, Boosts
            your SEO ranking and more.
          </p>
        </div>
      </div>

      <div className="briefContainerTwo">
        <div className="briefingwebmaintenancetwo">
          <h3 className="headerwebmaintenancetwo">
            You Need Expert for Website Maintenance
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Maintaining a business's websites may not be your top priority but
            Now since you know website maintenance is linked to multiple aspects
            of your business it is important to provide the complete maintenance
            to make your website run successfully. The website maintenance can
            be done by yourself, but partnering with an experienced web agency
            such as YODISO takes the burden off of your team and ensures your
            website is always updated and secured.
          </p>
        </div>

        <div>
          <img src={WebDeveloper2} className="imagewebmaintenancetwo" />
        </div>
      </div>

      <BookFreeConsultation showmodal={handlemodal} closemodal={handleClose} />
      <div className="serviceBtn">
        <Button
          className="serviceButton"
          variant="primary"
          onClick={() => setHandleModal(true)}
        >
          Book Free Consultation
        </Button>
      </div>

      <Testimonial />

      <h3 className="additionalServiceHeading">
        Additional Services to scale your business
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          {cardOne.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
        <div className="cardLayoutAdditional">
          {cardTwo.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default WebsiteMaintenance;
