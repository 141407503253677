import React, { useState } from "react";
import {
  FooterContainer,
  SubContainer,
  CompanyName,
  CompanyDescription,
  Subtitle,
  SubPara,
  CopyRight,
  Container,
  FootContainer,
  SubContainerInfoOne,
  SubContainerInfoTwo,
  SubContainerInfoThree,
  Button,
} from "./FooterElements";
import FooterImg from "../../images/Footer.png";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const PostData = (e) => {
    e.preventDefault();
    fetch("/contactusfooter", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        message,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          console.log("error");
        } else {
          console.log("success");
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
        }
      })
      .catch((err) => console.log(err));
  };

  let history = useHistory();
  const redirectwebdesign = () => {
    history.push("/webdesign");
  };
  const redirectandroiddev = () => {
    history.push("/androidappdev");
  };
  const redirectsoftwaredev = () => {
    history.push("/webapplication");
  };
  const redirectseopage = () => {
    history.push("/ecomseoservice");
  };
  const redirectsocialpage = () => {
    history.push("/socialmediamarketing");
  };
  const redirectppc = () => {
    history.push("/payperclick");
  };
  const redirectsocialmanage = () => {
    history.push("/socialmediapagemanagement");
  };
  const redirectcustomsoftware = () => {
    history.push("/customsoftware");
  };
  const redirectContactUs = () => {
    history.push("/contactus");
  };
  const redirectCareer = () => {
    history.push("/career");
  };
  return (
    <>
      <FooterContainer style={{ backgroundImage: `url(${FooterImg})` }}>
        <Container>
          <SubContainer>
            <CompanyName>YODISO</CompanyName>
            <CompanyDescription>
              Our approach to Web Development or E-commerce Development or
              Digital Marketing works on simple ethics and that is, to help you
              become one of the top brands in your business domain in your area.
            </CompanyDescription>
          </SubContainer>

          <SubContainerInfoOne>
            <Subtitle>Services</Subtitle>
            <SubPara onClick={redirectwebdesign} style={{ cursor: "pointer" }}>
              ● Website Designing & Development
            </SubPara>
            <SubPara onClick={redirectandroiddev} style={{ cursor: "pointer" }}>
              ● Android and iOS App Development
            </SubPara>
            <SubPara
              onClick={redirectsoftwaredev}
              style={{ cursor: "pointer" }}
            >
              ● Software/Web Application
            </SubPara>
            <SubPara onClick={redirectseopage} style={{ cursor: "pointer" }}>
              ● SEO Services
            </SubPara>
            <SubPara onClick={redirectsocialpage} style={{ cursor: "pointer" }}>
              ● Social Media Marketing
            </SubPara>
            <SubPara onClick={redirectppc} style={{ cursor: "pointer" }}>
              ● Pay Per Click – PPC
            </SubPara>
            <SubPara
              onClick={redirectsocialmanage}
              style={{ cursor: "pointer" }}
            >
              ● Social Media Page Management
            </SubPara>
            <SubPara
              onClick={redirectcustomsoftware}
              style={{ cursor: "pointer" }}
            >
              ● Custom Software Development
            </SubPara>
          </SubContainerInfoOne>

          <SubContainerInfoTwo>
            <Subtitle>Company</Subtitle>
            <SubPara style={{ cursor: "pointer" }}>
              <HashLink
                style={{ color: "white", textDecoration: "none" }}
                to="/#about"
              >
                ● About Company
              </HashLink>
            </SubPara>
            <SubPara>● Core Team</SubPara>
            <SubPara style={{ cursor: "pointer" }} onClick={redirectCareer}>
              ● Career
            </SubPara>
            <SubPara style={{ cursor: "pointer" }}>● Customer Reviews</SubPara>
            <SubPara style={{ cursor: "pointer" }} onClick={redirectContactUs}>
              ● Contact Us
            </SubPara>

            <SubPara style={{ cursor: "pointer" }} onClick={redirectContactUs}>
              ● Location
            </SubPara>
          </SubContainerInfoTwo>

          <SubContainerInfoThree>
            <Subtitle>Contact Us</Subtitle>
            <SubPara>
              19th Floor, Kamdhenu Business Center,
              Kharghar, Navi Mumbai, Mumbai, Maharashtra-410210
            </SubPara>
            <SubPara>+91-9137511786</SubPara>
            <SubPara>
              <Form.Group>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <br />
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <br />
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <Form.Control
                  style={{ height: 70 }}
                  size="sm"
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Message"
                />
              </Form.Group>
              <Button variant="primary" onClick={PostData}>
                Send
              </Button>
            </SubPara>
          </SubContainerInfoThree>
        </Container>
        <FootContainer>
          <CopyRight>Copyright © 2021. All Rights Reserved.</CopyRight>
        </FootContainer>
      </FooterContainer>
    </>
  );
};

export default Footer;
