import React, { useState,useEffect } from "react";
import Navbar from "../../Navbar/index";
import SocialMediaMarketingbanner from "../../../images/Social-Media-Marketing-Header.png";
import Testimonial from "../../Testimonial/Testimonial";
import WebApplication from "../../../images/webapplication.svg";
import EcommerceSol from "../../../images/ecommercesol.svg";
import SocialMedia from "../../../images/socialmedia.svg";
import MobileApp from "../../../images/mobileapp.svg";
import Footer from "../../Footer/index";
import "./SocialMediaMarketing.css";
import Sidebar from "../../Sidebar/index";
import SocialMediaMarketingImg1 from "../../../images/socialmediamarketingimg1.png";
import Button from "react-bootstrap/Button";
import BookFreeConsultation from "../../BookFreeConsultation/BookFreeConsultation";
import { Helmet } from "react-helmet";
import Enquiry from "../../EnquiryForm/Enquiry";
import ReactPixel from "react-facebook-pixel";


const cardOne = [
  {
    image: WebApplication,
    name: "Search Engine Optimization",
    link: "/ecomseoservice",
    desc:
      "Our SEO specialists are committed to creating better evolving strategies in-line with the latest search engine guidelines to maintain your website's ranking.",
  },
  {
    image: EcommerceSol,
    name: "Digital Marketing",
    link: "/socialmediamarketing",
    desc:
      "Digital marketing gives you infinite opportunities to grow your business. We have an expert digital marketing team to help you gain maximum out of your digital marketing.",
  },
  {
    image: EcommerceSol,
    name: "Google Ad",
    link: "/googlead",
    desc:
      "We help you manage your business google ads campaign more efficiently and effectively by leveraging our team of google ads experts.",
  },
];

const cardTwo = [
  {
    image: SocialMedia,
    name: "Lead Generation",
    link: "/performancemarketing",
    desc:
      "We are specialized in lead generation digital marketing, not only that but we also help you receive the lead in real-time So that you can immediately convert the lead to customer.",
  },
  {
    image: MobileApp,
    name: "Social Media Marketing",
    link: "/socialmediamarketing",
    desc:
      "Your business needs an experienced and expert digital marketing agency to ensure your business growth and handle all your social media channels.",
  },
  {
    image: MobileApp,
    name: "Email Marketing",
    link: "/emailmarketing",
    desc:
      "Email marketing has the highest ROI as compared to any other marketing. Yodiso will provide the best email marketing and campaign solution for your business.",
  },
];

function SocialMediaMarketing() {
  
  const [isOpen, setIsOpen] = useState(false);
  const [handlemodal, setHandleModal] = useState(false);

  const handleClose = () => {
    setHandleModal(false);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };
   useEffect(() => {
     const advancedMatching = { em: "madhav.mishra@yodiso.com" };
     const options = {
       autoConfig: true,
       debug: false,
     };
     ReactPixel.init(277473760122474, advancedMatching, options);
     ReactPixel.pageView();
   }, []);
  return (
    <div style={{ height: 2000 }} style={{ scrollbarWidth: "none" }}>
      <Helmet>
        <title>YODISO | SOCIAL MEDIA MARKETING</title>
      </Helmet>
      <Enquiry/>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <div className="navpagecontainer">
        <img src={SocialMediaMarketingbanner} className="webbanner" />

        <div className="top-left"></div>
        <h2 className="centered">
          Latest Technology | User-Friendly | Low Maintenance
        </h2>
      </div>

      <div style={{ textAlign: "center", marginTop: 80 }}>
        <h1 className="servicesHeading">
          Social Media <span style={{ color: "blue" }}>Marketing</span>
        </h1>
      </div>

      <div className="briefContainerOne">
        <div>
          <img
            src={SocialMediaMarketingImg1}
            className="imagesocialmediamarketone"
          />
        </div>
        <div className="briefingsocialmediamarketone">
          <h3 className="headersocialmediamarketone">
            Why is Social Media Important For Business?
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            A one-liner answer to this question is; Social media gives an
            infinite potential opportunity for exponential business growth.
            Facebook alone has more than 2 billion monthly active users who
            on-an-average spends an hour a day on social media.
          </p>
        </div>
      </div>

      <BookFreeConsultation showmodal={handlemodal} closemodal={handleClose} />
      <div className="serviceBtn">
        <Button
          className="serviceButton"
          variant="primary"
          onClick={() => setHandleModal(true)}
        >
          Book Free Consultation
        </Button>
      </div>

      <h3 className="additionalServiceHeading">
        Benefits of Social Media marketing
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={WebApplication} />
            <p className="boxPara">Brand Cognization</p>
            <p className="boxSubPara">
              Social media is one of the best platforms to grab customer
              attention towards your brand social media can get your brand in
              front of large audiences much more quickly and easily.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Build Awareness</p>
            <p className="boxSubPara">
              Alone Facebook has 2.7 billion of active user and counting, the
              social media give you more exposure among those active users who
              are actively searching for the brand like yours.
            </p>
          </div>

          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Conversation around the brand</p>
            <p className="boxSubPara">
              With a simple post, you can create a buzz in social media around
              your brand and create community conversation.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Communicate Authority</p>
            <p className="boxSubPara">
              Posting original contents, regularly replying to the customer
              comments and question demonstrate that your business care about
              customer satisfaction. Satisfied customers are eager to spread the
              word.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Tell Your Brand Story</p>
            <p className="boxSubPara">
              Social media is a great medium to tell story about your brand,
              doesn’t matter if it is normal or extensive but it has a great
              impact on your brand’s image.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Show Authenticity</p>
            <p className="boxSubPara">
              91% of customers says that they are ready to reward a brand or buy
              or endorse their authentic contents.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Encourage Engagement</p>
            <p className="boxSubPara">
              Social media will help you to reach a wider audience and talk not
              just about your brand but other related topic and the services you
              provide and encourage the user to engage with your business.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Build Customer Loyalty</p>
            <p className="boxSubPara">
              Customers see social media as a service channel too and they love
              to directly communicate with you through these channels.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Re-direct customer to your Website</p>
            <p className="boxSubPara">
              With engaging posts and promotions on social media, you can easily
              and effortlessly redirect your customer to the website to showcase
              your products and services.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Re-target Your Audience</p>
            <p className="boxSubPara">
              Social media allow us to precisely retarget our potential
              customers and easily upsell your product and services.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Grow Affordably</p>
            <p className="boxSubPara">
              Social media promotion and brand building is one of the most
              affordable way to give boost to your brand.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">A/B Testing for Best Results</p>
            <p className="boxSubPara">
              A/B testing can easily help you understand which audience or
              contents are more prompt to influence the customer decision
              towards your product and services.
            </p>
          </div>
        </div>
      </div>

      <Testimonial />

      <h3 className="additionalServiceHeading">
        Additional Services to scale your business
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          {cardOne.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
        <div className="cardLayoutAdditional">
          {cardTwo.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default SocialMediaMarketing;


