import React, { useState, useEffect } from "react";
import Navbar from "../../Navbar/index";
import SEObanner from "../../../images/SEO-Header.png";
import Testimonial from "../../Testimonial/Testimonial";
import WebApplication from "../../../images/webapplication.svg";
import EcommerceSol from "../../../images/ecommercesol.svg";
import SocialMedia from "../../../images/socialmedia.svg";
import MobileApp from "../../../images/mobileapp.svg";
import Footer from "../../Footer/index";
import "./SEOService.css";
import Sidebar from "../../Sidebar/index";
import WebDeveloper1 from "../../../images/webdevimg1.png";
import SEOImg2 from "../../../images/seo-services-img2.png";
import Button from "react-bootstrap/Button";
import BookFreeConsultation from "../../BookFreeConsultation/BookFreeConsultation";
import Enquiry from "../../EnquiryForm/Enquiry";
import { Helmet } from "react-helmet";
import ReactPixel from "react-facebook-pixel";

const cardOne = [
  {
    image: WebApplication,
    name: "Search Engine Optimization",
    link: "/ecomseoservice",
    desc:
      "Our SEO specialists are committed to creating better evolving strategies in-line with the latest search engine guidelines to maintain your website's ranking.",
  },
  {
    image: EcommerceSol,
    name: "Digital Marketing",
    link: "/socialmediamarketing",
    desc:
      "Digital marketing gives you infinite opportunities to grow your business. We have an expert digital marketing team to help you gain maximum out of your digital marketing.",
  },
  {
    image: EcommerceSol,
    name: "Google Ad",
    link: "/googlead",
    desc:
      "We help you manage your business google ads campaign more efficiently and effectively by leveraging our team of google ads experts.",
  },
];

const cardTwo = [
  {
    image: SocialMedia,
    name: "Lead Generation",
    link: "/performancemarketing",
    desc:
      "We are specialized in lead generation digital marketing, not only that but we also help you receive the lead in real-time So that you can immediately convert the lead to customer.",
  },
  {
    image: MobileApp,
    name: "Social Media Marketing",
    link: "/socialmediamarketing",
    desc:
      "Your business needs an experienced and expert digital marketing agency to ensure your business growth and handle all your social media channels.",
  },
  {
    image: MobileApp,
    name: "Email Marketing",
    link: "/emailmarketing",
    desc:
      "Email marketing has the highest ROI as compared to any other marketing. Yodiso will provide the best email marketing and campaign solution for your business.",
  },
];

function SEOService() {
  const [isOpen, setIsOpen] = useState(false);

  const [handlemodal, setHandleModal] = useState(false);

  const handleClose = () => {
    setHandleModal(false);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const advancedMatching = { em: "madhav.mishra@yodiso.com" };
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(277473760122474, advancedMatching, options);
    ReactPixel.pageView();
  }, []);
  
  return (
    <div style={{ height: 2000 }} style={{ scrollbarWidth: "none" }}>
      <Helmet>
        <title>YODISO | SEO SERVICE</title>
      </Helmet>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Enquiry />
      <div className="navpagecontainer">
        <img src={SEObanner} className="webbanner" />

        <div className="top-left"></div>
        <h2 className="centered">
          Latest Technology | User-Friendly | Low Maintenance
        </h2>
      </div>

      <div style={{ textAlign: "center", marginTop: 80 }}>
        <h1 className="servicesHeading">
          Ecommerce SEO <span style={{ color: "blue" }}>Service</span>
        </h1>
      </div>

      <div className="briefContainerOne">
        <div>
          <img src={SEOImg2} className="imageseoone" />
        </div>
        <div className="briefingseoone">
          <h3 className="headerseoone">Rank Up In The Google Search</h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Many businesses struggle to drive organic traffic on their website
            because the websites are missing in the search result hence their
            conversions are less and eventually, they are unable to generate
            sales from the e-commerce website. We have helped many businesses to
            get their website ranked up in the search result and generate
            revenue through our E-commerce SEO Service.
          </p>
        </div>
      </div>

      <div className="briefContainerTwo">
        <div className="briefingseotwo">
          <h3 className="headerseotwo">Hire Us For SEO Services</h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            If you are looking for the best e-commerce SEO company, you are in
            the right place indeed. Yodiso is one of the best e-commerce SEO
            agency has helped many e-commerce websites ranked up in the google
            search result. We at Yodiso bring you expert e-commerce SEO services
            to enhance your organic traffic and boost your business out of your
            e-commerce websites.
          </p>
        </div>

        <div>
          <img src={WebDeveloper1} className="imageseotwo" />
        </div>
      </div>
      <BookFreeConsultation showmodal={handlemodal} closemodal={handleClose} />
      <div className="serviceBtn">
        <Button
          className="serviceButton"
          variant="primary"
          onClick={() => setHandleModal(true)}
        >
          Book Free Consultation
        </Button>
      </div>

      <h3 className="additionalServiceHeading">
        What our Ecommerce SEO Services Includes
      </h3>
      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={WebApplication} />
            <p className="boxPara">Conducting Through SEO Audits</p>
            <p className="boxSubPara">
              Our SEO specialists are committed to creating better evolving
              strategies in-line with the latest search engine guidelines to
              maintain your website's ranking.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Technical SEO Services</p>
            <p className="boxSubPara">
              Digital marketing gives you infinite opportunities to grow your
              business. We have an expert digital marketing team to help you
              gain maximum out of your digital marketing.
            </p>
          </div>

          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Keywords Research & Optimization</p>
            <p className="boxSubPara">
              We help you manage your business google ads campaign more
              efficiently and effectively by leveraging our team of google ads
              experts.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Titles Tags & Meta Descriptions</p>
            <p className="boxSubPara">
              We are specialized in lead generation digital marketing, not only
              that but we also help you receive the lead in real-time So that
              you can immediately convert the lead to customer.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">
              Google Analytics setup with a conversion tracking
            </p>
            <p className="boxSubPara">
              Your business needs an experienced and expert digital marketing
              agency to ensure your business growth and handle all your social
              media channels.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">On-Page SEO Implementation</p>
            <p className="boxSubPara">
              Email marketing has the highest ROI as compared to any other
              marketing. Yodiso will provide the best email marketing and
              campaign solution for your business.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={WebApplication} />
            <p className="boxPara">Schema Implementation</p>
            <p className="boxSubPara">
              Our SEO specialists are committed to creating better evolving
              strategies in-line with the latest search engine guidelines to
              maintain your website's ranking.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Making Content Engaging</p>
            <p className="boxSubPara">
              Digital marketing gives you infinite opportunities to grow your
              business. We have an expert digital marketing team to help you
              gain maximum out of your digital marketing.
            </p>
          </div>

          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Conversion Rate Optimization</p>
            <p className="boxSubPara">
              We help you manage your business google ads campaign more
              efficiently and effectively by leveraging our team of google ads
              experts.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={WebApplication} />
            <p className="boxPara">Link Building Services</p>
            <p className="boxSubPara">
              Our SEO specialists are committed to creating better evolving
              strategies in-line with the latest search engine guidelines to
              maintain your website's ranking.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Off-Page SEO Implementation</p>
            <p className="boxSubPara">
              Digital marketing gives you infinite opportunities to grow your
              business. We have an expert digital marketing team to help you
              gain maximum out of your digital marketing.
            </p>
          </div>

          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">robots.txt optimization</p>
            <p className="boxSubPara">
              We help you manage your business google ads campaign more
              efficiently and effectively by leveraging our team of google ads
              experts.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={WebApplication} />
            <p className="boxPara">Professional Blogs and Articles</p>
            <p className="boxSubPara">
              Our SEO specialists are committed to creating better evolving
              strategies in-line with the latest search engine guidelines to
              maintain your website's ranking.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Competitor intelligence report</p>
            <p className="boxSubPara">
              Digital marketing gives you infinite opportunities to grow your
              business. We have an expert digital marketing team to help you
              gain maximum out of your digital marketing.
            </p>
          </div>

          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Detailed Reporting</p>
            <p className="boxSubPara">
              We help you manage your business google ads campaign more
              efficiently and effectively by leveraging our team of google ads
              experts.
            </p>
          </div>
        </div>
      </div>

      <Testimonial />

      <h3 className="additionalServiceHeading">
        Additional Services to scale your business
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          {cardOne.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
        <div className="cardLayoutAdditional">
          {cardTwo.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default SEOService;
