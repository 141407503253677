import React, { useState,useEffect } from "react";
import Navbar from "../../Navbar/index";
import IOSbanner from "../../../images/iOS-app-Header.png";
import WebDeveloper from "../../../images/webdeveloper.svg";
import Testimonial from "../../Testimonial/Testimonial";
import WebApplication from "../../../images/webapplication.svg";
import EcommerceSol from "../../../images/ecommercesol.svg";
import SocialMedia from "../../../images/socialmedia.svg";
import MobileApp from "../../../images/mobileapp.svg";
import Footer from "../../Footer/index";
import "./IosAppDev.css";
import Sidebar from "../../Sidebar/index";
import MobileAppimg1 from "../../../images/MobileAppimg1.png";
import MobileAppimg2 from "../../../images/MobileAppimg2.png";
import Button from "react-bootstrap/Button";
import BookFreeConsultation from "../../BookFreeConsultation/BookFreeConsultation";
import { Helmet } from "react-helmet";
import Enquiry from "../../EnquiryForm/Enquiry";
import ReactPixel from "react-facebook-pixel";

const cardOne = [
  {
    image: WebApplication,
    name: "Search Engine Optimization",
    link: "/ecomseoservice",
    desc:
      "Our SEO specialists are committed to creating better evolving strategies in-line with the latest search engine guidelines to maintain your website's ranking.",
  },
  {
    image: EcommerceSol,
    name: "Digital Marketing",
    link: "/socialmediamarketing",
    desc:
      "Digital marketing gives you infinite opportunities to grow your business. We have an expert digital marketing team to help you gain maximum out of your digital marketing.",
  },
  {
    image: EcommerceSol,
    name: "Google Ad",
    link: "/googlead",
    desc:
      "We help you manage your business google ads campaign more efficiently and effectively by leveraging our team of google ads experts.",
  },
];

const cardTwo = [
  {
    image: SocialMedia,
    name: "Lead Generation",
    link: "/performancemarketing",
    desc:
      "We are specialized in lead generation digital marketing, not only that but we also help you receive the lead in real-time So that you can immediately convert the lead to customer.",
  },
  {
    image: MobileApp,
    name: "Social Media Marketing",
    link: "/socialmediamarketing",
    desc:
      "Your business needs an experienced and expert digital marketing agency to ensure your business growth and handle all your social media channels.",
  },
  {
    image: MobileApp,
    name: "Email Marketing",
    link: "/emailmarketing",
    desc:
      "Email marketing has the highest ROI as compared to any other marketing. Yodiso will provide the best email marketing and campaign solution for your business.",
  },
];
function IosAppDev() {
  const [isOpen, setIsOpen] = useState(false);
  const [handlemodal, setHandleModal] = useState(false);

  const handleClose = () => {
    setHandleModal(false);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };
   useEffect(() => {
     const advancedMatching = { em: "madhav.mishra@yodiso.com" };
     const options = {
       autoConfig: true,
       debug: false,
     };
     ReactPixel.init(277473760122474, advancedMatching, options);
     ReactPixel.pageView();
   }, []);
  return (
    <div style={{ height: 2000 }} style={{ scrollbarWidth: "none" }}>
      <Helmet>
        <title>YODISO | iOS APP DEVELOPMENT</title>
      </Helmet>
      <Enquiry/>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <div className="navpagecontainer">
        <img src={IOSbanner} className="webbanner" />

        <div className="top-left"></div>
        <h2 className="centered">
          Latest Technology | User-Friendly | Low Maintenance
        </h2>
      </div>

      <div style={{ textAlign: "center", marginTop: 80 }}>
        <h1 className="servicesHeading">
          iOS <span style={{ color: "blue" }}>Development</span>
        </h1>
      </div>

      <div className="briefContainerOne">
        <div>
          <img src={MobileAppimg1} className="imageandroidone" />
        </div>
        <div className="briefingandroidone">
          <h3 className="headerandroidone">Hire iOS Developers From Yodiso</h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Here we believe that just having an app is not the exact solution
            for your business but having a user-friendly, reliable and scalable
            app is the need of your business. Yodiso will understand your
            requirements and make a custom iOS application as per your business
            need which is highly scalable and reliable. Our team of experts will
            develop and deploy the application on the app store.
          </p>
        </div>
      </div>

      <div className="briefContainerTwo">
        <div className="briefingandroidtwo">
          <h3 className="headerandroidtwo">Mix of Development and Marketing</h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Yodiso will develop high-performance iOS applications for your
            business. Our team of digital marketing experts will also help you
            in marketing your application. We are one of the most reliable
            agency in iOS app development. We are known for the on-time project
            delivery. Hire us for professional iOS app development for your
            business.
          </p>
        </div>

        <div>
          <img src={MobileAppimg2} className="imageandroidtwo" />
        </div>
      </div>
      <BookFreeConsultation showmodal={handlemodal} closemodal={handleClose} />
      <div className="serviceBtn">
        <Button
          className="serviceButton"
          variant="primary"
          onClick={() => setHandleModal(true)}
        >
          Book Free Consultation
        </Button>
      </div>

      <Testimonial />

      <h3 className="additionalServiceHeading">
        Additional Services to scale your business
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          {cardOne.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
        <div className="cardLayoutAdditional">
          {cardTwo.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default IosAppDev;
