import React, { useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import { Link as LinkR } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "./NavbarElements.css";
import WordPressDev from "../../images/wordpressdev.png";
import WebHosting from "../../images/webhosting.png";
import WebDesign from "../../images/webdesign.png";
import WebDev from "../../images/webdevelopment.png";
import AndroidDev from "../../images/androiddev.png";
import IosDev from "../../images/iosdev.png";
import WebApplication from "../../images/webapplication.png";
import WebSetting from "../../images/websetting.png";
import WebMaintenance from "../../images/webmaintenance.png";
import SSLCerti from "../../images/sslcerti.png";
import PaymentGateway from "../../images/paymentgateway.png";
import SEOService from "../../images/seoservice.png";
import PerformanceMarketing from "../../images/performancemarketing.png";
import FbMarketing from "../../images/fbmarketing.png";
import GoogleAds from "../../images/googleads.png";
import PageManagement from "../../images/pagemanagement.png";
import PayPerClick from "../../images/payperclick.png";
import PageOptimization from "../../images/optimization.png";
import SocialMediaMarketing from "../../images/socialmediamarketing.png";
import EmailMarketing from "../../images/emailmarketing.png";


const Navbar = ({ toggle }) => {
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  const [showecommerce, setShowEcommerce] = useState(false);
  const handleCloseEcommerce = (e) => {
    setShowEcommerce(false);
  };
  const handleShowEcommerce = (e) => {
    setShowEcommerce(!showecommerce);
  };

  const [showmarketing, setShowMarketing] = useState(false);
  const handleCloseMarketing = () => {
    setShowMarketing(false);
  };
  const handleShowMarketing = () => {
    setShowMarketing(!showmarketing);
  };

  return (
    <>
      <nav className="Nav">
        <div className="NavbarContainer">
          <LinkR className="NavLogo" to="/">
            Yodiso
          </LinkR>
          <div className="MobileIcon" onClick={toggle}>
            <FaBars />
          </div>

          <ul className="NavMenu">
            <li className="NavItem">
              <a className="NavLinks" href="/">
                Home
              </a>
            </li>
            <li className="NavItem">
              <HashLink className="NavLinks" to="/#about">
                About
              </HashLink>
              {/* <LinkS className="NavLinks" to="/about">
                About
              </LinkS> */}
            </li>
            <li className="NavItem">
              <NavDropdown
                title="Web Services"
                className="dropdownBar"
                id="collasible-nav-dropdown"
                show={show}
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}
              >
                <div
                  style={{
                    display: "flex",
                    width: 600,
                    height: 230,
                    padding: 10,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={WebDesign}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/webdesign">Website Designing</a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={WebDev}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/webdevelopment">Website Development</a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={WebMaintenance}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/webmaintenance">Website Maintenance</a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={WebHosting}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/webhosting">Website Hosting</a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={WebApplication}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/webapplication">Web Application</a>
                      </p>
                    </div>
                    <div style={{ marginLeft: 50 }}>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={WebSetting}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/customsoftware">Custom Software Solutions</a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={WordPressDev}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/wordpressdev">WordPress Development</a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={AndroidDev}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/androidappdev">Android App Development</a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={IosDev}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/iosappdev">iOS App Development</a>
                      </p>
                    </div>
                  </div>
                </div>
              </NavDropdown>
            </li>
            <li className="NavItem">
              <NavDropdown
                title="E-commerce Solution"
                id="collasible-nav-dropdown"
                className="dropdownBar"
                show={showecommerce}
                onMouseEnter={handleShowEcommerce}
                onMouseLeave={handleCloseEcommerce}
              >
                <div
                  style={{
                    display: "flex",
                    width: 700,
                    height: 165,
                    padding: 15,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={WebDev}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/ecomwebsitedev">
                          Ecommerce Website Development
                        </a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={WebHosting}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/ecomwebsitehosting">Ecommerce Web Hosting</a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={SEOService}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/ecomseoservice">Ecommerce SEO Services</a>
                      </p>
                    </div>
                    <div style={{ marginLeft: 20 }}>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={WebMaintenance}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/ecomwebsitemaintenance">
                          Ecommerce Website Maintenance
                        </a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={PaymentGateway}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/ecompaymentgateway">
                          Payment Gateway Integration
                        </a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={SSLCerti}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/ecomsslcertificate">SSL certificate</a>
                      </p>
                    </div>
                  </div>
                </div>
              </NavDropdown>
            </li>
            <li className="NavItem">
              <NavDropdown
                title="Marketing Services"
                id="collasible-nav-dropdown"
                className="dropdownBar"
                show={showmarketing}
                onMouseEnter={handleShowMarketing}
                onMouseLeave={handleCloseMarketing}
              >
                <div
                  style={{
                    display: "flex",
                    width: 600,
                    height: 180,
                    padding: 10,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={PerformanceMarketing}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/performancemarketing">
                          Performance Marketing
                        </a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={FbMarketing}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/facebookmarketing">Facebook Marketing</a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={SocialMediaMarketing}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/socialmediamarketing">
                          Social Media Marketing
                        </a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={PayPerClick}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/payperclick">Pay Per Click-PPC</a>
                      </p>
                    </div>
                    <div style={{ marginLeft: 30 }}>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={PageManagement}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/socialmediapagemanagement">
                          Social Media Page Management
                        </a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={PageOptimization}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/socialmediaoptimization">
                          Social Media Optimization
                        </a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={GoogleAds}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/googlead">Google Ad</a>
                      </p>
                      <p style={{ display: "flex" }}>
                        <img
                          style={{ marginRight: 5 }}
                          src={EmailMarketing}
                          alt="trial"
                          width="30"
                          height="30"
                        />
                        <a href="/emailmarketing">Email Marketing</a>
                      </p>
                    </div>
                  </div>
                </div>
              </NavDropdown>
            </li>
            <li className="NavItem">
              <a className="NavLinks" href="/career">
                Career
              </a>
            </li>
            <li className="NavItem">
              <a className="NavLinks" href="/contactus">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
