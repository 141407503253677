import React, { useState, useEffect } from "react";
import "./Career.css";
import Navbar from "../Navbar/index";
import Footer from "../Footer/index";
import Sidebar from "../Sidebar/index";
import { Modal, Button, Form,InputGroup } from "react-bootstrap";
import CheckedTick from "../../images/checked.gif";
import ReactPixel from "react-facebook-pixel";


function Career() {
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [position, setPosition] = useState("");
  const [reference, setReference] = useState("");
  const [cv, setCV] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [responseType, setResponseType] = useState("");
  const [smShow, setSmShow] = useState(false);


  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

    const successAlert = () => (
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body closeButton>
          <strong style={{ textAlign: "center" }}>
            Thank you for contacting us, we will get back to you soon!
          </strong>
          <div className="successgif">
            <img src={CheckedTick} alt="loading..." width="200" height="200" />
          </div>
        </Modal.Body>
      </Modal>
    );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

   useEffect(() => {
     const advancedMatching = { em: "madhav.mishra@yodiso.com" };
     const options = {
       autoConfig: true,
       debug: false,
     };
     ReactPixel.init(277473760122474, advancedMatching, options);
     ReactPixel.pageView();
   }, []);
   
  const postDetails = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", cv);
    formData.append("upload_preset", "yodisowebproject");
    formData.append("cloud_name", "yodiso");

    fetch("https://api.cloudinary.com/v1_1/yodiso/image/upload", {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        fetch("/job/jobapply", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            age,
            position,
            reference,
            resume: data.url,
            suggestion,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setSubmitted(true);
            if (data.error) {
              setValidated(true)
            } else {
              handleClose()
              setSmShow(true);
              setResponseType("success");
              setName('')
              setEmail('')
              setAge('')
              setPosition('')
              setReference('')
              setSuggestion('')
              
            }
          })
          .catch((err) => {
            console.log(err);
          });

      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="careerPage">
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <div style={{ marginTop: 130 }}>
        {submitted && (responseType === "error" ? "" : successAlert())}
        <h2 style={{ textAlign: "center", color: "blue", fontWeight: "bold" }}>
          Work with us. Grow with us.
        </h2>
        <p className="p-large">"Let's have coffee together."</p>
      </div>
      <div className="careerContainer">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <ul className="job-list">
              <li className="job-preview">
                <div className="content float-left">
                  <h4 className="job-title">
                    Business Development Manager (3)
                  </h4>
                  <h5 className="company">Mumbai, IN</h5>
                </div>
                <a
                  onClick={handleShow}
                  href="#"
                  className="btn btn-apply float-sm-right float-xs-left"
                >
                  Apply
                </a>
              </li>
              <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title style={{ color: "black" }}>
                    Job Description
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="applyBtnContainer">
                    <header className="header">
                      <h1 id="title" className="text-center">
                        Yodiso Job Appplicaion
                      </h1>
                      <p id="description" className="description-text-center">
                        Please fill everything out to the best of your
                        knowledge.
                      </p>
                    </header>

                    <Form
                      className="form-group"
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Form.Row
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Form.Group
                          style={{ width: 300 }}
                          controlId="validationCustom01"
                        >
                          <Form.Label>Name *</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Form.Group>

                        <Form.Group
                          style={{ width: 300 }}
                          controlId="validationCustomUsername"
                        >
                          <Form.Label>Email Address *</Form.Label>
                          <InputGroup hasValidation>
                            <Form.Control
                              type="text"
                              placeholder="name@example.com"
                              aria-describedby="inputGroupPrepend"
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </InputGroup>
                        </Form.Group>

                        <div className="form-group">
                          <label id="number-label" htmlFor="number">
                            Age *
                          </label>
                          <input
                            controlId="validationCustom02"
                            required
                            style={{ width: 293 }}
                            type="number"
                            name="age"
                            id="number"
                            min="18"
                            max="100"
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                            className="form-control"
                            placeholder="Age"
                          />
                        </div>

                        <Form.Group
                          style={{ width: 300 }}
                          controlId="validationCustom03"
                        >
                          <Form.Label>
                            What position are you applying for?*
                          </Form.Label>

                          <Form.Control
                            required
                            as="select"
                            custom
                            value={position}
                            onChange={(e) => setPosition(e.target.value)}
                          >
                            <option>Select role</option>
                            <option value="Business Development Manager">
                              Business Development Manager
                            </option>
                            <option value="Graphics Designer">
                              Graphics Designer
                            </option>
                            <option value="Content Writers">
                              Content Writers
                            </option>
                            <option value="Web Designer & Developer">
                              Web Designer & Developer
                            </option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please upload CV.
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          style={{ width: 300 }}
                          controlId="validationCustom04"
                        >
                          <Form.Label>
                            How did you find out about us? *
                          </Form.Label>

                          <Form.Control
                            required
                            as="select"
                            custom
                            value={reference}
                            onChange={(e) => setReference(e.target.value)}
                          >
                            <option default>Select Reference</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Linkedin">Linkedin</option>
                            <option value="Twitter">Twitter</option>
                            <option value="Indeed">Indeed</option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="validationCustom04">
                          <Form.File
                            required
                            style={{ width: 300 }}
                            id="exampleFormControlFile1"
                            label="Upload your CV *"
                            type="file"
                            onChange={(e) => setCV(e.target.files[0])}
                          />
                        </Form.Group>
                        <div className="form-group">
                          <p>Any comments or suggestions?</p>
                          <textarea
                            style={{ width: 300 }}
                            id="comments"
                            className="input-textarea"
                            name="comment"
                            value={suggestion}
                            onChange={(e) => setSuggestion(e.target.value)}
                            placeholder="Enter your comment here..."
                          ></textarea>
                        </div>
                      </Form.Row>
                      <Button
                        variant="success"
                        style={{
                          width: 400,
                          marginLeft: 28,
                          marginBottom: 20,
                        }}
                        type="submit"
                        id="submit"
                        className="submit-button"
                        onClick={postDetails}
                      >
                        Apply Now
                      </Button>
                    </Form>
                  </div>
                </Modal.Body>
              </Modal>

              <li className="job-preview">
                <div className="content float-left">
                  <h4 className="job-title">Graphics Designer (2)</h4>
                  <h5 className="company">Mumbai, IN</h5>
                </div>
                <a
                  onClick={handleShow}
                  href="#"
                  className="btn btn-apply float-sm-right float-xs-left"
                >
                  Apply
                </a>
              </li>
              <li className="job-preview">
                <div className="content float-left">
                  <h4 className="job-title">Content Writers (2)</h4>
                  <h5 className="company">Mumbai, IN</h5>
                </div>
                <a
                  onClick={handleShow}
                  href="#"
                  className="btn btn-apply float-sm-right float-xs-left"
                >
                  Apply
                </a>
              </li>
              <li className="job-preview">
                <div className="content float-left">
                  <h4 className="job-title">Web Designer & Developer (2)</h4>
                  <h5 className="company">Mumbai, IN</h5>
                </div>
                <a
                  onClick={handleShow}
                  href="#"
                  className="btn btn-apply float-sm-right float-xs-left"
                >
                  Apply
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Career;
