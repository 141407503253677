import React, { useState,useEffect } from "react";
import Navbar from "../../Navbar/index";
import EcommerceWebbanner from "../../../images/Ecommerce-header-Header.png";
import Testimonial from "../../Testimonial/Testimonial";
import WebApplication from "../../../images/webapplication.svg";
import EcommerceSol from "../../../images/ecommercesol.svg";
import SocialMedia from "../../../images/socialmedia.svg";
import MobileApp from "../../../images/mobileapp.svg";
import Footer from "../../Footer/index";
import "./WebsiteDevelopment.css";
import Sidebar from "../../Sidebar/index";
import EcommerceWebDevImg1 from "../../../images/EcommerceWebDevimg1.png";
import EcommerceWebDevImg2 from "../../../images/EcommerceWebDevimg2.png";
import Button from "react-bootstrap/Button";
import BookFreeConsultation from "../../BookFreeConsultation/BookFreeConsultation";
import { Helmet } from "react-helmet";
import Enquiry from "../../EnquiryForm/Enquiry";
import ReactPixel from "react-facebook-pixel";

const cardOne = [
  {
    image: WebApplication,
    name: "Search Engine Optimization",
    link: "/ecomseoservice",
    desc:
      "Our SEO specialists are committed to creating better evolving strategies in-line with the latest search engine guidelines to maintain your website's ranking.",
  },
  {
    image: EcommerceSol,
    name: "Digital Marketing",
    link: "/socialmediamarketing",
    desc:
      "Digital marketing gives you infinite opportunities to grow your business. We have an expert digital marketing team to help you gain maximum out of your digital marketing.",
  },
  {
    image: EcommerceSol,
    name: "Google Ad",
    link: "/googlead",
    desc:
      "We help you manage your business google ads campaign more efficiently and effectively by leveraging our team of google ads experts.",
  },
];

const cardTwo = [
  {
    image: SocialMedia,
    name: "Lead Generation",
    link: "/performancemarketing",
    desc:
      "We are specialized in lead generation digital marketing, not only that but we also help you receive the lead in real-time So that you can immediately convert the lead to customer.",
  },
  {
    image: MobileApp,
    name: "Social Media Marketing",
    link: "/socialmediamarketing",
    desc:
      "Your business needs an experienced and expert digital marketing agency to ensure your business growth and handle all your social media channels.",
  },
  {
    image: MobileApp,
    name: "Email Marketing",
    link: "/emailmarketing",
    desc:
      "Email marketing has the highest ROI as compared to any other marketing. Yodiso will provide the best email marketing and campaign solution for your business.",
  },
];

function WebsiteDevelopment() {
  const [isOpen, setIsOpen] = useState(false);

  const [handlemodal, setHandleModal] = useState(false);

  const handleClose = () => {
    setHandleModal(false);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const advancedMatching = { em: "madhav.mishra@yodiso.com" };
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(277473760122474, advancedMatching, options);
    ReactPixel.pageView();
  }, []);
  
  return (
    <div style={{ height: 2000 }} style={{ scrollbarWidth: "none" }}>
      <Helmet>
        <title>YODISO | WEB DEVELOPMENT</title>
      </Helmet>
      <Enquiry />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <div className="navpagecontainer">
        <img src={EcommerceWebbanner} className="webbanner" />

        <div className="top-left"></div>
        <h2 className="centered">
          Latest Technology | User-Friendly | Low Maintenance
        </h2>
      </div>

      <div style={{ textAlign: "center", marginTop: 80 }}>
        <h1 className="servicesHeading">
          Ecommerce Website <span style={{ color: "blue" }}>Development</span>
        </h1>
      </div>

      <div className="briefContainerOne">
        <div>
          <img src={EcommerceWebDevImg1} className="imageEcomWebDevone" />
        </div>
        <div className="briefingEcomWebDevone">
          <h3 className="headerEcomWebDevone">
            Build Direct Relation With Your Customer
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            To the cutthroat competition all-around your business needs a
            powerful e-commerce website to compete in the market. Do you know
            95% of customers compare online before they make a final decision to
            buy the product online, over 60% of offline buyers are reading and
            comparing product details online before they buy a product. This is
            the time when you need to take your business online as your
            customers are searching for it online.
          </p>
        </div>
      </div>

      <div className="briefContainerTwo">
        <div className="briefingEcomWebDevtwo">
          <h3 className="headerEcomWebDevtwo">
            Hire Us For Website Development
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Whether you are looking for B2C Online Store or B2B E-commerce
            website or a C2C E-commerce website, Yodios will help you in
            developing an end-to-end E-commerce business solution. If you have
            already decided on the type of E-commerce website, now is a time for
            you to choose the right partner who can help you in setting up your
            e-commerce business. Yodiso is one of the most reliable and trusted
            partners for providing e-commerce solutions for many businesses. Now
            is time for you to hire us to develop your end-to-end e-commerce
            website for your business.
          </p>
        </div>

        <div>
          <img src={EcommerceWebDevImg2} className="imageEcomWebDevtwo" />
        </div>
      </div>
      <BookFreeConsultation showmodal={handlemodal} closemodal={handleClose} />
      <div className="serviceBtn">
        <Button
          className="serviceButton"
          variant="primary"
          onClick={() => setHandleModal(true)}
        >
          Book Free Consultation
        </Button>
      </div>

      <h3 className="additionalServiceHeading">
        What we deliver in e-commerce solution:
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={WebApplication} />
            <p className="boxPara">Custom Shopping Cart</p>
            <p className="boxSubPara">
              We provide a custom shopping cart to your website. Our developers’
              team will help you integrate with any existing billing software
              you are currently using.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Mobile Responsive</p>
            <p className="boxSubPara">
              Our extensive tech development teams will provide a
              mobile-friendly e-commerce website that works on all devices.
            </p>
          </div>

          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Superior UI/UX</p>
            <p className="boxSubPara">
              Yodiso has a dedicated team for UI & UX and they have great
              experience to make your e-commerce website look stunning and user
              friendly across all devices.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Multi Payment Option</p>
            <p className="boxSubPara">
              Our tech team will help you integrate any payment getaway into
              your e-commerce websites.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Social Media Integration</p>
            <p className="boxSubPara">
              We will integrate your social media pages to your e-commerce
              website so that you can easily sell e-commerce products through
              social media channels.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Wishlist</p>
            <p className="boxSubPara">
              Our tech and development team will create a custom Wishlist option
              in your e-commerce website so that if anyone wants to buy it later
              then can put those products into their Wishlist.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Secure Payment Getaway</p>
            <p className="boxSubPara">
              We believe in performance marketing, Organic and paid marketing.
              Our Marketing teams are Always committed to your business growth.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Custom Admin Panel</p>
            <p className="boxSubPara">
              We provide a custom admin panel for your e-commerce website. You
              can track your sales through your website, update any products
              that you want to update by yourself.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Easy to Use Checkout</p>
            <p className="boxSubPara">
              We provide super simple cart checkout in your e-commerce websites,
              your customer can easily checkout and make the payment to place
              the order online.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Social Media Integration</p>
            <p className="boxSubPara">
              We will integrate your social media pages to your e-commerce
              website so that you can easily sell e-commerce products through
              social media channels.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Rating and Reviews</p>
            <p className="boxSubPara">
              We provide a seamless rating and review system for your e-commerce
              products; customers can easily and conveniently rate and review
              your e-commerce products.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Shipping API Integration</p>
            <p className="boxSubPara">
              To streamline your logistic and shipping our tech team will help
              you integrate the shipping API in your e-commerce website.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Search Engine Optimized </p>
            <p className="boxSubPara">
              We mix our tech team and the SEO expert team to develop your
              e-commerce websites optimized for search engine optimization.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Reporting Tool</p>
            <p className="boxSubPara">
              We provide smart and intelligent business reporting tool for your
              e-commerce website.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Scalable Solution</p>
            <p className="boxSubPara">
              We provide a scalable e-commerce solution that has the ability to
              adapt quickly to change without disruption.
            </p>
          </div>
        </div>
      </div>

      <Testimonial />

      <h3 className="additionalServiceHeading">
        Additional Services to scale your business
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          {cardOne.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
        <div className="cardLayoutAdditional">
          {cardTwo.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default WebsiteDevelopment;
