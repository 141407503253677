import React, { useState,useEffect,useRef } from "react";
import Card from "react-bootstrap/Card";
import star from "../../images/star.png";
import Profile from "../../images/circle.png";
import Carousel from "react-bootstrap/Carousel";
import "./Testimonial.css";
import SunriseHospital from '../../images/sunrisehospital.png'
import DentalPulse from '../../images/dentalpulse.png'
import PalasiaSalon from '../../images/palasiasalon.png'
import PerfectDance from '../../images/perfect10dance.png'
import MatrixFlagship from '../../images/matrixflagship.png'


function Testimonial() {
 
  return (
    <div className="testimonialMainContainer">
      <h3 style={{ textAlign: "center", color: "white", paddingTop: 30 }}>
        Customer Reviews
      </h3>
      <Carousel>
        <Carousel.Item>
          <div className="reviewContainer">
            <Card className="testimonialCard">
              <div style={{ display: "flex" }}>
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
              </div>
              <p className="cardPara">
                "It has been a great experience hiring Yodiso as our digital
                marketing partner, we have taken their social media marketing
                and web development services all I have to say is they are an
                amazing team."
              </p>
              <div style={{ display: "flex" }}>
                <img src={PalasiaSalon} width="70" height="70" />
                <div style={{ marginLeft: 30 }}>
                  <p>Ruchita</p>
                  <p style={{ marginTop: -5 }}>Palasia Salon & Academy</p>
                </div>
              </div>
            </Card>

            <Card className="testimonialCard">
              <div style={{ display: "flex" }}>
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
              </div>
              <p className="cardPara">
                "Experience with Yodiso has been Great I like their working
                approach and overall client handling skills. I am using their
                digital marketing services and I am highly satisfied with the
                lead generation and lead conversions."
              </p>
              <div style={{ display: "flex" }}>
                <img src={Profile} width="70" height="70" />
                <div style={{ marginLeft: 30 }}>
                  <p>Sushma</p>
                  <p style={{ marginTop: -5 }}>The Mirror Salon</p>
                </div>
              </div>
            </Card>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="reviewContainer">
            <Card className="testimonialCard">
              <div style={{ display: "flex" }}>
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
              </div>
              <p className="cardPara">
                "I have been working with Madhav and Yodiso team for about two
                and half years on website design and development, they have full
                team of expert, I just want to say Their dedication, integrity
                and commitment is tremendous. "
              </p>
              <div style={{ display: "flex" }}>
                <img src={SunriseHospital} width="70" height="70" />
                <div style={{ marginLeft: 30 }}>
                  <p>Dr. A. S. Deshmukh</p>
                  <p style={{ marginTop: -5 }}>
                    Sunrise Multispecialty Hospital
                  </p>
                </div>
              </div>
            </Card>

            <Card className="testimonialCard">
              <div style={{ display: "flex" }}>
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
              </div>
              <p className="cardPara">
                "I would say Yodiso is one of the best web designing and social
                media marketing agency in Mumbai. I have got my website and
                social media page from them, they have exactly delivered how I
                want my website and social media page."
              </p>
              <div style={{ display: "flex" }}>
                <img src={DentalPulse} width="70" height="70" />
                <div style={{ marginLeft: 30 }}>
                  <p>Dr. Amit Sashte</p>
                  <p style={{ marginTop: -5 }}>Dental Pulse Clinic</p>
                </div>
              </div>
            </Card>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div className="reviewContainer">
            <Card className="testimonialCard">
              <div style={{ display: "flex" }}>
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
              </div>
              <p className="cardPara">
                "At a point in the initial I was very disappointed with the lead
                quality, but the way entire team has optimized the ad was just
                amazing and I had started getting quality of leads and high
                conversion and now I am very much satisfied with Yodiso."
              </p>
              <div style={{ display: "flex" }}>
                <img src={PerfectDance} width="70" height="70" />
                <div style={{ marginLeft: 30 }}>
                  <p>Majid Khan</p>
                  <p style={{ marginTop: -5 }}>Perfect 10 Dance & Fitness</p>
                </div>
              </div>
            </Card>

            <Card className="testimonialCard">
              <div style={{ display: "flex" }}>
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
                <img src={star} width="30" height="30" />
              </div>
              <p className="cardPara">
                "In a single word if you are looking for an agency who can take
                care of your business growth simply go with Yodiso. Initially I
                had started with Facebook Ad but with kind of commitment and
                result, they have shown now Yodiso is taking care of my entire
                marketing, website designing, social media marketing and social
                media page optimization. "
              </p>
              <div style={{ display: "flex" }}>
                <img src={MatrixFlagship} width="70" height="70" />
                <div style={{ marginLeft: 30 }}>
                  <p>Sudhir Sinha</p>
                  <p style={{ marginTop: -5 }}>Matrix Flagship Salon</p>
                </div>
              </div>
            </Card>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Testimonial;
