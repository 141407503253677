import React from "react";
import "./ClientPage.css";
import AdoreHair from '../../images/clientlogo/Adore-Hair-and-Beauty.png'
import BodyPower from '../../images/clientlogo/Body-Power.png'
import ClubNasha from '../../images/clientlogo/Club-Nasha.png'
import DentalPulse from '../../images/clientlogo/Dental-Pulse-Clinic.png'
import BodhiWellness from '../../images/clientlogo/DR-Bodhi-Wellness.png'
import HappyHair from '../../images/clientlogo/Happy-Hair.png'
import LeechiSalon from '../../images/clientlogo/Leechis-Salon.png'
import Matrix from '../../images/clientlogo/Matrix-Flagship-Salon.png'
import PalasiaSalon from '../../images/clientlogo/Palasia-Salon-and-Academy.png'
import Perfect10Dance from '../../images/clientlogo/Perfect-10-Dance.png'
import PriyasWellness from '../../images/clientlogo/Priyas-Wellness.png'
import SRHomeSalon from '../../images/clientlogo/S-&-R-Home-Salon.png'
import StatusAesthetic from '../../images/clientlogo/Status-Aesthetic-Clinic.png'
import SubtitleWive from '../../images/clientlogo/Subtitels-Wives.png'
import SummanDental from '../../images/clientlogo/Summan-Dental-Clinic.png'
import SunriseMultispeciality from '../../images/clientlogo/Sunrise-Multispeciality-Hospital.png'
import TheMirror from '../../images/clientlogo/The-Mirror-Unisex-Salon.png'
import TrueSalon from '../../images/clientlogo/True-You-Salon.png'
import UnnatiHospital from '../../images/clientlogo/Unnati-Hospital.png'
import ZylonSalon from '../../images/clientlogo/Zylon-Salon.png'
import Carousel from "react-bootstrap/Carousel";

function ClientPage() {
  return (
    <div className="clientlogodiv">
      <h2
        style={{
          color: "black",
          textAlign: "center",
          fontWeight: "bold",
          paddingTop: 45,
          paddingBottom: 45,
        }}
      >
        Our Partners
      </h2>
      <Carousel style={{ textAlign: "center" }}>
        <Carousel.Item interval={1000}>
          <img className="clientlogo" src={AdoreHair} alt="First slide" />
          <img className="clientlogo" src={BodyPower} alt="First slide" />
          <img className="clientlogo" src={ClubNasha} alt="First slide" />
          <img className="clientlogo" src={DentalPulse} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item interval={500} className="carouseldiv">
          <img className="clientlogo" src={BodhiWellness} alt="First slide" />
          <img className="clientlogo" src={HappyHair} alt="First slide" />
          <img className="clientlogo" src={LeechiSalon} alt="First slide" />
          <img className="clientlogo" src={Matrix} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item className="carouseldiv">
          <img className="clientlogo" src={PalasiaSalon} alt="First slide" />
          <img className="clientlogo" src={Perfect10Dance} alt="First slide" />
          <img className="clientlogo" src={PriyasWellness} alt="First slide" />
          <img className="clientlogo" src={SRHomeSalon} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item className="carouseldiv">
          <img className="clientlogo" src={StatusAesthetic} alt="First slide" />
          <img className="clientlogo" src={SubtitleWive} alt="First slide" />
          <img className="clientlogo" src={SummanDental} alt="First slide" />
          <img
            className="clientlogo"
            src={SunriseMultispeciality}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item className="carouseldiv">
          <img className="clientlogo" src={TheMirror} alt="First slide" />
          <img className="clientlogo" src={TrueSalon} alt="First slide" />
          <img className="clientlogo" src={UnnatiHospital} alt="First slide" />
          <img className="clientlogo" src={ZylonSalon} alt="First slide" />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default ClientPage;
