import styled from "styled-components";

export const ChooseContainer = styled.div`
  color: #fff;
  background: #fff;
  height: 900px;

  @media screen and (max-width: 1024px) {
    padding: 80px;
    height: 1030px;
  }
  @media screen and (max-width: 480px) {
    text-align: left;
    height: 100% !important;
  }
  @media screen and (max-width: 768px) {
    height: 1100px;
  }
`;

export const Heading = styled.h2`
  margin-top: 35px;
  margin-bottom: 24px;
  font-size: 35px;
  display: grid;
  line-height: 1.1;
  font-weight: 600;
  justify-items: center;
  color: #000;

  @media screen and (max-width: 480px) {
    font-size: 25px;
    width:220px;
  }
`;
export const SubHead = styled.p`
  margin-bottom: 35px;
  font-size: 18px;
  align-items: left;
  text-align: center;
  padding:20px;
  margin-top:-10px;
  line-height: 24px;
  width: 100%;
  color: #000;

  @media screen and (max-width: 1024px) {
    width: 900px;
    align-items: left;
    text-align: center;
    margin-left: -30px;
  }
  @media screen and (max-width: 768px) {
    width: 730px;
    font-size: 17px;
    align-items: left;
    text-align: center;
    margin-left: -75px;
  }
  @media screen and (max-width: 480px) {
    font-size: 17px;
    width:360px;
    padding:30px;
    justify-content:center;
    align-items:center;
    
  }
`;
export const SubHeading = styled.p`
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 25px;
  display: grid;
  line-height: 1.1;
  font-weight: 100;
  justify-items: center;
  color: #000;

  @media screen and (max-width: 480px) {
    font-size: 25px;
  }
  @media screen and (max-width: 768px) {
    width: 250px;
    font-size: 22px;
  }
`;
export const Subtitle = styled.p`
  margin-bottom: 35px;
  max-width: 250px;
  font-size: 18px;
  text-align: center;
  line-height: 24px;
  color: #000;

  @media screen and (max-width: 768px) {
    width: 230px;
    padding:5px;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  z-index: 1;
  height: 300px;
  width: 100%;
  margin-bottom:20px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction:column;
    z-index: 1;
    height: 1000px;
    width: 100%;
    margin-top:80px;
    margin-right: auto;
    margin-left: -160px;
    padding: 0 24px;
    justify-content: center;
  }
`;

export const MainWrapperTwo = styled.div`
  display: flex;
  z-index: 1;
  height: 300px;
  width: 100%;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    z-index: 1;
    height: 1000px;
    width: 100%;
    margin-top: 150px;
    margin-right: auto;
    margin-left: -160px;
    padding: 0 24px;
    justify-content: center;
  }
`;


export const ChooseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  height:370px;
  align-items: center;

  @media screen and (max-width: 768px) {
    width:500px;
    height:450px;
  }
`;

export const Img = styled.img`
  width: 30%;
  height: 40%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;
