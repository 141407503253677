import React from 'react'
import {
  ChooseContainer,
  Heading,
  Subtitle,
  MainWrapper,
  MainWrapperTwo,
  ChooseWrapper,
  Img,
  SubHeading,
  SubHead
} from "./ChooseElements";

const ChooseUs = () => {
  return (
    <>
      <ChooseContainer id="whychooseus">
        <Heading>Why Choose Us ?</Heading>
        <SubHead>
          There could be numerous reasons why we are here including reliability,
          latest technologies, and constant updates & support but at the core,
          we are here because of two reasons one being the customers who have
          trusted us and our deliverables to them, and the second being our
          entire team members who are consistently striving to work hard on 100%
          customer satisfaction.
        </SubHead>
        {/* first wrapper */}
        <MainWrapper>
          <ChooseWrapper>
            <Img src={require("../../images/time.svg").default} />
            <SubHeading>Fast and Quick Result</SubHeading>
            <Subtitle>
              We work quickly and efficiently to provide the best result.
            </Subtitle>
          </ChooseWrapper>
          <ChooseWrapper>
            <Img src={require("../../images/apps.svg").default} />
            <SubHeading>Powerful Apps</SubHeading>
            <Subtitle>
              Our team offers a wide range of powerful and sustainable apps.
            </Subtitle>
          </ChooseWrapper>
          <ChooseWrapper>
            <Img src={require("../../images/saving.svg").default} />
            <SubHeading>Money-Saving</SubHeading>
            <Subtitle>
              Our products and services cost less than their analogs by other
              companies.
            </Subtitle>
          </ChooseWrapper>
        </MainWrapper>

        {/* second wrapper */}
        <MainWrapperTwo>
          <ChooseWrapper>
            <Img src={require("../../images/support.svg").default} />
            <SubHeading>Efficient Support</SubHeading>
            <Subtitle>
              Yodiso offers extensive Chat and Call support to its customers all
              over the world.
            </Subtitle>
          </ChooseWrapper>
          <ChooseWrapper>
            <Img src={require("../../images/ideas.svg").default} />
            <SubHeading>Innovative Technology</SubHeading>
            <Subtitle>
              Our developers use the latest technologies to deliver sustainable
              and long-lasting Apps.
            </Subtitle>
          </ChooseWrapper>
          <ChooseWrapper>
            <Img src={require("../../images/usability.svg").default} />
            <SubHeading>User-Friendly Design</SubHeading>
            <Subtitle>
              User-Friendly UX and Improved Usability are the core features of
              our developments and services.
            </Subtitle>
          </ChooseWrapper>
        </MainWrapperTwo>
      </ChooseContainer>
    </>
  );
};

export default ChooseUs
