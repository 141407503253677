import React, { useState,useEffect } from "react";
import Video from "../../videos/video.mp4";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  CardLayout,
  CardBox,
  ImgLogo,
  BoxPara,
  BoxSubPara,
  Spanpara,
  CardLayoutContainer,
} from "./HeroElements";

import Animation from "../Animation/Animation";
import { Button } from "../ButtonElements";
import WebApplication from "../../images/webapplication.svg";
import EcommerceSol from "../../images/ecommercesol.svg";
import SocialMedia from "../../images/socialmedia.svg";
import MobileApp from "../../images/mobileapp.svg";
import Enquiry from "../EnquiryForm/Enquiry"
import ReactPixel from "react-facebook-pixel";


const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
    useEffect(() => {
      const advancedMatching = { em: "madhav.mishra@yodiso.com" };
      const options = {
        autoConfig: true,
        debug: false,
      };
      ReactPixel.init(277473760122474, advancedMatching, options);
      ReactPixel.pageView();
    }, []);
  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
      </HeroBg>
      <HeroContent>
        <HeroH1>
          Committed to Technology, Committed to your Business Development
        </HeroH1>
        <HeroP>
          We are tech passionate and Digital Marketing agency. Fulfilling your
          requirements is our #1 Priority, Your Satisfaction is our
          Satisfaction.
        </HeroP>
        <Enquiry />
        <HeroBtnWrapper>
          <Button
            to="whychooseus"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
          >
            Get started {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>

        <Animation />

        <CardLayoutContainer>
          <CardLayout>
            <CardBox>
              <ImgLogo src={WebApplication} />
              <BoxPara>Desktop and Web Application</BoxPara>
              <BoxSubPara>
                We believe every business is unique, therefore we provide custom
                software solutions to our clients.
              </BoxSubPara>
            </CardBox>
            <CardBox>
              <ImgLogo src={EcommerceSol} />
              <BoxPara>E-Commerce Solution</BoxPara>
              <BoxSubPara>
                Have an e-commerce idea in your mind? Let me build an end-to-end
                tech infrastructure for you. Fully customized for all devices!
              </BoxSubPara>
            </CardBox>
          </CardLayout>

          <CardLayout>
            <CardBox>
              <ImgLogo src={SocialMedia} />
              <BoxPara>Digital Marketing</BoxPara>
              <BoxSubPara>
                We believe in performance marketing, Organic and paid marketing.
                Our Marketing teams are Always committed to your business
                growth.
              </BoxSubPara>
            </CardBox>
            <CardBox>
              <ImgLogo src={MobileApp} />
              <BoxPara>Android & iOS App Development</BoxPara>
              <BoxSubPara>
                Looking for an Android or iOS App Development? We are one of the
                most trusted agency for Android and iOS development in Mumbai.
              </BoxSubPara>
            </CardBox>
          </CardLayout>
        </CardLayoutContainer>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
