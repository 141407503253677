import React, { useState,useEffect } from "react";
import Navbar from "../../Navbar/index";
import Facebookbanner from "../../../images/Facebook-Marketing-Header.png";
import Testimonial from "../../Testimonial/Testimonial";
import WebApplication from "../../../images/webapplication.svg";
import EcommerceSol from "../../../images/ecommercesol.svg";
import SocialMedia from "../../../images/socialmedia.svg";
import MobileApp from "../../../images/mobileapp.svg";
import Footer from "../../Footer/index";
import "./FacebookMarketing.css";
import Sidebar from "../../Sidebar/index";
import FacebookMarketingimg1 from "../../../images/Facebook-marketingimg1.png";
import FacebookMarketingimg2 from "../../../images/Facebook-marketingimg2.png";
import Button from "react-bootstrap/Button";
import BookFreeConsultation from "../../BookFreeConsultation/BookFreeConsultation";
import { Helmet } from "react-helmet";
import Enquiry from "../../EnquiryForm/Enquiry";
import ReactPixel from "react-facebook-pixel";

const cardOne = [
  {
    image: WebApplication,
    name: "Search Engine Optimization",
    link: "/ecomseoservice",
    desc:
      "Our SEO specialists are committed to creating better evolving strategies in-line with the latest search engine guidelines to maintain your website's ranking.",
  },
  {
    image: EcommerceSol,
    name: "Digital Marketing",
    link: "/socialmediamarketing",
    desc:
      "Digital marketing gives you infinite opportunities to grow your business. We have an expert digital marketing team to help you gain maximum out of your digital marketing.",
  },
  {
    image: EcommerceSol,
    name: "Google Ad",
    link: "/googlead",
    desc:
      "We help you manage your business google ads campaign more efficiently and effectively by leveraging our team of google ads experts.",
  },
];

const cardTwo = [
  {
    image: SocialMedia,
    name: "Lead Generation",
    link: "/performancemarketing",
    desc:
      "We are specialized in lead generation digital marketing, not only that but we also help you receive the lead in real-time So that you can immediately convert the lead to customer.",
  },
  {
    image: MobileApp,
    name: "Social Media Marketing",
    link: "/socialmediamarketing",
    desc:
      "Your business needs an experienced and expert digital marketing agency to ensure your business growth and handle all your social media channels.",
  },
  {
    image: MobileApp,
    name: "Email Marketing",
    link: "/emailmarketing",
    desc:
      "Email marketing has the highest ROI as compared to any other marketing. Yodiso will provide the best email marketing and campaign solution for your business.",
  },
];

function FacebookMarketing() {
  const [isOpen, setIsOpen] = useState(false);
  const [handlemodal, setHandleModal] = useState(false);

  const handleClose = () => {
    setHandleModal(false);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };
    useEffect(() => {
      const advancedMatching = { em: "madhav.mishra@yodiso.com" };
      const options = {
        autoConfig: true,
        debug: false,
      };
      ReactPixel.init(277473760122474, advancedMatching, options);
      ReactPixel.pageView();
    }, []);
  return (
    <div style={{ height: 2000 }} style={{ scrollbarWidth: "none" }}>
      <Helmet>
        <title>YODISO | FB MARKETING</title>
      </Helmet>
      <Enquiry/>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <div className="navpagecontainer">
        <img src={Facebookbanner} className="webbanner" />

        <div className="top-left"></div>
        <h2 className="centered">
          Latest Technology | User-Friendly | Low Maintenance
        </h2>
      </div>

      <div style={{ textAlign: "center", marginTop: 80 }}>
        <h1 className="servicesHeading">
          Facebook <span style={{ color: "blue" }}>Marketing</span>
        </h1>
      </div>

      <div className="briefContainerOne">
        <div>
          <img src={FacebookMarketingimg1} className="imagefbone" />
        </div>
        <div className="briefingfbone">
          <h3 className="headerfbone">Facebook Marketing By Yodiso</h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            A large number of your customer uses Facebook and Instagram on daily
            basis, around 2.7 billion people uses Facebook every month to
            connect with friends and family and discover things that matter to
            them, on the other side Instagram has around 1 billion active users
            monthly and 90% of people on Instagram are following someone or
            other business and more than 50% of user are more interested in the
            brand when they see brand ads on Instagram. Facebook and Instagram
            are one of the top social media platforms to find new customers and
            build long-lasting relationships with them.
          </p>
        </div>
      </div>

      <div className="briefContainerTwo">
        <div className="briefingfbtwo">
          <h3 className="headerfbtwo">
            Set Goal, Plan It, Execute It and Get Result
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Now since most of your customers are on Facebook on Instagram, both
            of these platforms become our prime advertising channels to build
            long-lasting customer relationships. Yodiso has an innovative
            Facebook Advertising plan and solution for your business. Yodiso has
            a combined team of experts and a young mind to blow your Facebook
            marketing with expertise and make it look trendy. Yoidso will take
            care of your entire Facebook marketing development right from
            creative-design to measure the marketing performance of your
            advertisement.
          </p>
        </div>

        <div>
          <img src={FacebookMarketingimg2} className="imagefbtwo" />
        </div>
      </div>
      <BookFreeConsultation showmodal={handlemodal} closemodal={handleClose} />
      <div className="serviceBtn">
        <Button
          className="serviceButton"
          variant="primary"
          onClick={() => setHandleModal(true)}
        >
          Book Free Consultation
        </Button>
      </div>

      <h3 className="additionalServiceHeading">
        Type of Promotion of Facebook:
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={WebApplication} />
            <p className="boxPara">Brand Building</p>
            <p className="boxSubPara">
              Let’s build your brand with rich content, regular post, and trendy
              design. We take your brand from zero to hero.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Post Boost</p>
            <p className="boxSubPara">
              Reach to a wider audience and showcase your business in front of
              more eyeballs. It is one of the best techniques to drive traffic
              to the website.
            </p>
          </div>

          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Increase Likers and Followers</p>
            <p className="boxSubPara">
              We help you in increasing the likes and followers of your social
              media business pages across all channels.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Increase Web visitor through click</p>
            <p className="boxSubPara">
              We will help you increase the website visitors by engaging post
              and boost.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Carousal Ad</p>
            <p className="boxSubPara">
              We will create carousel ads and posts to promote your multiple
              product and services in a single ad or post.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Lead Gen Ad</p>
            <p className="boxSubPara">
              Through our Lead Gen campaign, we generate low-cost leads for your
              business and send them real-time to you.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Video Ad</p>
            <p className="boxSubPara">
              We will create video promotion ads and video posts to promote your
              business across the social media platforms.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Conversion Ad</p>
            <p className="boxSubPara">
              Our AI based automatic conversion ads will help you generate sale
              and lead for your business online.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Messenger Ad</p>
            <p className="boxSubPara">
              Messenger ads have the highest conversion ratio, with the
              automatic chat messenger ads generates qualified leads for your
              business.
            </p>
          </div>
        </div>
      </div>

      <h3 className="additionalServiceHeading">What is unique with Yodios:</h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={WebApplication} />
            <p className="boxPara">Real-time Lead</p>
            <p className="boxSubPara">
              The windows of opportunity to convert a lead to a customer are
              very less therefore we send the real-time leads to you.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Creative-Design Support</p>
            <p className="boxSubPara">
              Hire us we will take care of everything from design to marketing
              performance you just seat back and relax our team will take care
              of it.
            </p>
          </div>

          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Dedicated Account Manager</p>
            <p className="boxSubPara">
              A dedicated manager will monitor your campaign performance and get
              it to optimize for the best result.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">High ROI</p>
            <p className="boxSubPara">
              Our performance marketing will help you gain multifold returns of
              marketing budgets and overall growth in your business.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Lead Management System</p>
            <p className="boxSubPara">
              A dedicated lead management system to track the status of your
              lead and follow-up with them on time to maximize lead conversions.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">24/7 Support</p>
            <p className="boxSubPara">
              We are available 24/7 to help you address your query, feel free to
              reach out to us any time you want.
            </p>
          </div>
        </div>
      </div>
      <Testimonial />

      <h3 className="additionalServiceHeading">
        Additional Services to scale your business
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          {cardOne.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
        <div className="cardLayoutAdditional">
          {cardTwo.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default FacebookMarketing;
