import styled from "styled-components";

export const ValueContainer = styled.div`
  color: #fff;
  background: #fff;

  @media screen and (max-width: 768px) {
    padding: 100px 0;
    height: 800px;
  }
  @media screen and (max-width: 480px) {
    height: 1380px;
  }
`;

export const Heading = styled.h2`
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 35px;
  display: grid;
  line-height: 1.1;
  font-weight: 600;
  justify-items: center;
  color: #000;

  @media screen and (max-width: 480px) {
    font-size: 25px;
  }
`;

export const SubHeading = styled.p`
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 25px;
  width:300px;
  display: grid;
  line-height: 1.1;
  font-weight: 100;
  justify-items: center;
  color: #000;

  @media screen and (max-width: 480px) {
    font-size: 25px;
  }
`;
export const Subtitle = styled.p`
  margin-bottom: 35px;
  max-width: 250px;
  font-size: 18px;
  text-align: center;
  line-height: 24px;
  color: #000;
`;

export const MainWrapper = styled.div`
  display: flex;
  z-index: 1;
  height: 300px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    z-index: 1;
    height: 100%;
    /* width: 100%;
    margin-right: auto;
    margin-left: -18px !important; */
    padding: 0 24px;
    justify-content: center;
  }
  @media screen and (max-width: 1024px) {
    display: flex;
    z-index: 1;
    height: 100%;
    width: 100%;
    margin-right: auto;
    margin-top:50px;
    margin-left: 1px;
    padding: 0 24px;
    justify-content: center;
  }
`;

export const MainSubWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 480px) {
    display:flex;
    flex-direction:column;
    flex:1;
    justify-content:center;
    align-items:center;
  }
  @media screen and (max-width: 768px) {
    margin-right: -70px;
    margin-left:-30px;
  }
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 300px;
  align-items: center;

  @media screen and (max-width: 1024px) {
    width: 450px;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-left:-25px;
  }
`;

export const Img = styled.img`
  width: 50%;
  height: 70px;
  margin: 0 0 10px 0;
  padding-right: 0;
`;
