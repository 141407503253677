import React, { useState,useEffect} from "react";
import Navbar from "../../Navbar/index";
import Webbanner from "../../../images/webdev.jpg";
import WebDeveloper from "../../../images/webdeveloper.svg";
import Testimonial from "../../Testimonial/Testimonial";
import WebApplication from "../../../images/webapplication.svg";
import EcommerceSol from "../../../images/ecommercesol.svg";
import SocialMedia from "../../../images/socialmedia.svg";
import MobileApp from "../../../images/mobileapp.svg";
import Footer from "../../Footer/index";
import "./WebsiteMaintenance.css";
import Sidebar from "../../Sidebar/index";
import WebDeveloper1 from "../../../images/webdevimg1.png";
import WebDeveloper2 from "../../../images/webdevimg2.png";
import Button from "react-bootstrap/Button";
import BookFreeConsultation from "../../BookFreeConsultation/BookFreeConsultation";
import { Helmet } from "react-helmet";
import Enquiry from "../../EnquiryForm/Enquiry";
import ReactPixel from "react-facebook-pixel";

const cardOne = [
  {
    image: WebApplication,
    name: "Search Engine Optimization",
    link: "/ecomseoservice",
    desc:
      "Our SEO specialists are committed to creating better evolving strategies in-line with the latest search engine guidelines to maintain your website's ranking.",
  },
  {
    image: EcommerceSol,
    name: "Digital Marketing",
    link: "/socialmediamarketing",
    desc:
      "Digital marketing gives you infinite opportunities to grow your business. We have an expert digital marketing team to help you gain maximum out of your digital marketing.",
  },
  {
    image: EcommerceSol,
    name: "Google Ad",
    link: "/googlead",
    desc:
      "We help you manage your business google ads campaign more efficiently and effectively by leveraging our team of google ads experts.",
  },
];

const cardTwo = [
  {
    image: SocialMedia,
    name: "Lead Generation",
    link: "/performancemarketing",
    desc:
      "We are specialized in lead generation digital marketing, not only that but we also help you receive the lead in real-time So that you can immediately convert the lead to customer.",
  },
  {
    image: MobileApp,
    name: "Social Media Marketing",
    link: "/socialmediamarketing",
    desc:
      "Your business needs an experienced and expert digital marketing agency to ensure your business growth and handle all your social media channels.",
  },
  {
    image: MobileApp,
    name: "Email Marketing",
    link: "/emailmarketing",
    desc:
      "Email marketing has the highest ROI as compared to any other marketing. Yodiso will provide the best email marketing and campaign solution for your business.",
  },
];

function WebsiteMaintenance() {
  const [isOpen, setIsOpen] = useState(false);
  const [handlemodal, setHandleModal] = useState(false);

  const handleClose = () => {
    setHandleModal(false);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const advancedMatching = { em: "madhav.mishra@yodiso.com" };
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(277473760122474, advancedMatching, options);
    ReactPixel.pageView();
  }, []);
  return (
    <div style={{ height: 2000 }} style={{ scrollbarWidth: "none" }}>
      <Helmet>
        <title>YODISO | WEB MAINTENANCE</title>
      </Helmet>
      <Enquiry/>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <div className="navpagecontainer">
        <img src={Webbanner} className="webbanner" />

        <div className="top-left"></div>
        <h2 className="centered">
          Latest Technology | User-Friendly | Low Maintenance
        </h2>
      </div>

      <div style={{ textAlign: "center", marginTop: 80 }}>
        <h1 className="servicesHeading">
          Ecommerce Website <span style={{ color: "blue" }}>Maintenance</span>
        </h1>
      </div>

      <div className="briefContainerOne">
        <div>
          <img src={WebDeveloper1} className="imagewebmaintenanceone" />
        </div>
        <div className="briefingwebmaintenanceone">
          <h3 className="headerwebmaintenanceone">
            Website Maintenance Services
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Having an e-commerce website is not enough; it requires regular
            updates with fresh content, trendy design, eye-catchy promotional
            contents and some technical touch in your e-commerce website. The
            regular updates help engage with website visitors and retain them
            for a long time and eventually they become your loyal customers and
            generate business for you.
          </p>
        </div>
      </div>

      <div className="briefContainerTwo">
        <div className="briefingwebmaintenancetwo">
          <h3 className="headerwebmaintenancetwo">
            Hire Us for Website Maintenance Services
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Not all the businesses pay time to regularly update the website as
            they have plenty of other works to look into therefore it becomes
            very difficult for all businesses to focus on this critical issue
            which matters most for their business, off-course they can’t do it
            at the cost of website maintenance. Yodiso is one of the most
            trusted partners for e-commerce website maintenance. Yodios provides
            end-to-end e-commerce website maintenance for the business.
          </p>
        </div>

        <div>
          <img src={WebDeveloper2} className="imagewebmaintenancetwo" />
        </div>
      </div>
      <BookFreeConsultation showmodal={handlemodal} closemodal={handleClose} />
      <div className="serviceBtn">
        <Button
          className="serviceButton"
          variant="primary"
          onClick={() => setHandleModal(true)}
        >
          Book Free Consultation
        </Button>
      </div>

      <h3 className="additionalServiceHeading">
        Services that we offer in the e-commerce website maintenance includes:
      </h3>
      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={WebApplication} />
            <p className="boxPara">Website Backup</p>
            <p className="boxSubPara">
              Our SEO specialists are committed to creating better evolving
              strategies in-line with the latest search engine guidelines to
              maintain your website's ranking.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Upgrading the Website Modules</p>
            <p className="boxSubPara">
              Digital marketing gives you infinite opportunities to grow your
              business. We have an expert digital marketing team to help you
              gain maximum out of your digital marketing.
            </p>
          </div>

          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Upgrading Plugins</p>
            <p className="boxSubPara">
              We help you manage your business google ads campaign more
              efficiently and effectively by leveraging our team of google ads
              experts.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Adding Contents to the Website</p>
            <p className="boxSubPara">
              We are specialized in lead generation digital marketing, not only
              that but we also help you receive the lead in real-time So that
              you can immediately convert the lead to customer.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Adding Images to the Website</p>
            <p className="boxSubPara">
              Your business needs an experienced and expert digital marketing
              agency to ensure your business growth and handle all your social
              media channels.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Virus & Malware Protection</p>
            <p className="boxSubPara">
              Email marketing has the highest ROI as compared to any other
              marketing. Yodiso will provide the best email marketing and
              campaign solution for your business.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={WebApplication} />
            <p className="boxPara">Hosting Server</p>
            <p className="boxSubPara">
              Our SEO specialists are committed to creating better evolving
              strategies in-line with the latest search engine guidelines to
              maintain your website's ranking.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Email Management</p>
            <p className="boxSubPara">
              Digital marketing gives you infinite opportunities to grow your
              business. We have an expert digital marketing team to help you
              gain maximum out of your digital marketing.
            </p>
          </div>

          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Regular Contents Update</p>
            <p className="boxSubPara">
              We help you manage your business google ads campaign more
              efficiently and effectively by leveraging our team of google ads
              experts.
            </p>
          </div>
        </div>
      </div>

      <Testimonial />

      <h3 className="additionalServiceHeading">
        Additional Services to scale your business
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          {cardOne.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
        <div className="cardLayoutAdditional">
          {cardTwo.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default WebsiteMaintenance;
