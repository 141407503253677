import styled from "styled-components";

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 860px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 480px) {
    height:950px;
  }
`;
export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  margin-bottom:-30px;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};


  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col2' 'col2 col2'`};
    display: flex;
    flex-direction: column-reverse;
    margin-top: 80px;
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
  margin-top: -130px;

  
  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
`;
export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  margin-top: -150px;
  grid-area: col2;

  @media screen and (max-width: 768px) {
    margin-top: -20px;
    
  }
`;
export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.p`
  color: #01bf71;
  font-size: 16px;
  height: 20px;
  justify-content: center;
  margin: 0 auto;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const AboutUsHeading = styled.h1`
  font-size: 48px;
  padding-top: 90px;
  justify-content: center;
  margin: 0 auto;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 30px;
    justify-content: center;
    margin: 0 auto;
    padding-top:100px;
  }
  @media screen and (max-width: 768px) {
    margin-top: -130px;
    margin-bottom:55px;
  }
`;

export const Heading = styled.h1`
  margin-bottom: 65px;
  font-size: 40px;
  margin-top:-40px;
  width: 650px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 30px;
    margin-top:20px !important;
    margin-bottom:70px !important;
    width: 350px;
    padding:15px;
    justify-content: center;
    margin: 0 auto;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  margin-top: -40px;
  font-size: 20px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};

  @media screen and (max-width: 480px) {
    padding: 15px;
    
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: 480px) {
    padding-left:20px;
    
  }
`;
export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
  
`;
export const Img = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0 0 10px 0;
  padding-right: 0;
  margin-top: -200px;

  @media screen and (max-width: 768px) {
    margin-top: -30px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 480px) {
    margin-top: -20px;
    margin-bottom: 40px;
    padding:5px;
  }
`;
