import React, { useState,useEffect } from "react";
import Navbar from "../../Navbar/index";
import Androidbanner from "../../../images/Android-App-Header.png";
import Testimonial from "../../Testimonial/Testimonial";
import WebApplication from "../../../images/webapplication.svg";
import EcommerceSol from "../../../images/ecommercesol.svg";
import SocialMedia from "../../../images/socialmedia.svg";
import MobileApp from "../../../images/mobileapp.svg";
import Footer from "../../Footer/index";
import "./AndroidApp.css";
import Sidebar from "../../Sidebar/index";
import MobileAppimg1 from "../../../images/MobileAppimg1.png";
import MobileAppimg2 from "../../../images/MobileAppimg2.png";
import Button from "react-bootstrap/Button";
import BookFreeConsultation from "../../BookFreeConsultation/BookFreeConsultation"
import { Helmet } from "react-helmet";
import Enquiry from "../../EnquiryForm/Enquiry";
import ReactPixel from "react-facebook-pixel";

const cardOne = [
  {
    image: WebApplication,
    name: "Search Engine Optimization",
    link: "/ecomseoservice",
    desc:
      "Our SEO specialists are committed to creating better evolving strategies in-line with the latest search engine guidelines to maintain your website's ranking.",
  },
  {
    image: EcommerceSol,
    name: "Digital Marketing",
    link: "/socialmediamarketing",
    desc:
      "Digital marketing gives you infinite opportunities to grow your business. We have an expert digital marketing team to help you gain maximum out of your digital marketing.",
  },
  {
    image: EcommerceSol,
    name: "Google Ad",
    link: "/googlead",
    desc:
      "We help you manage your business google ads campaign more efficiently and effectively by leveraging our team of google ads experts.",
  },
];

const cardTwo = [
  {
    image: SocialMedia,
    name: "Lead Generation",
    link: "/performancemarketing",
    desc:
      "We are specialized in lead generation digital marketing, not only that but we also help you receive the lead in real-time So that you can immediately convert the lead to customer.",
  },
  {
    image: MobileApp,
    name: "Social Media Marketing",
    link: "/socialmediamarketing",
    desc:
      "Your business needs an experienced and expert digital marketing agency to ensure your business growth and handle all your social media channels.",
  },
  {
    image: MobileApp,
    name: "Email Marketing",
    link: "/emailmarketing",
    desc:
      "Email marketing has the highest ROI as compared to any other marketing. Yodiso will provide the best email marketing and campaign solution for your business.",
  },
];

function AndroidApp() {
  const [isOpen, setIsOpen] = useState(false);
  const [handlemodal,setHandleModal] = useState(false);



  const handleClose = () => {
      setHandleModal(false)
  }
  
  const toggle = () => {
    setIsOpen(!isOpen);
  };
   useEffect(() => {
     const advancedMatching = { em: "madhav.mishra@yodiso.com" };
     const options = {
       autoConfig: true,
       debug: false,
     };
     ReactPixel.init(277473760122474, advancedMatching, options);
     ReactPixel.pageView();
   }, []);
  return (
    <div style={{ height: 2000 }} style={{ scrollbarWidth: "none" }}>
      <Helmet>
        <title>YODISO | ANDROID DEVELOPMENT</title>
      </Helmet>
      <Enquiry/>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <div className="navpagecontainer">
        <img src={Androidbanner} className="webbanner" />

        <div className="top-left"></div>
        <h2 className="centered">
          Latest Technology | User-Friendly | Low Maintenance
        </h2>
      </div>

      <div style={{ textAlign: "center", marginTop: 80 }}>
        <h1 className="servicesHeading">
          Android <span style={{ color: "blue" }}>Development</span>
        </h1>
      </div>

      <div className="briefContainerOne">
        <div>
          <img src={MobileAppimg1} className="imageandroidone" />
        </div>
        <div className="briefingandroidone">
          <h3 className="headerandroidone">Scalable Android App Development</h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Our team is an expert when it comes to Android App development.
            Yodiso can transform your concept into a Powerful, user-friendly
            Android Application. We believe that custom solutions make the large
            differences and our expert team will make handcraft android apps by
            keeping your audience in mind. Yodiso's expert team of android
            developers and UI designers are well professional and experienced to
            launch Android applications for various industries. Therefore we
            understand the criticality of developing and deploying a new Android
            app, growing your audience, and maximizing your investment.
          </p>
        </div>
      </div>

      <div className="briefContainerTwo">
        <div className="briefingandroidtwo">
          <h3 className="headerandroidtwo">Android Has Highest User Base</h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            According to GlobalSurvey, android's market cover is around 75%.
            Android being the majority holder in the mobile industry, anyone who
            is thinking about the business expansion online and android
            application will be the most economical and affordable option for
            them. Yodiso is one of the most trusted agency for Android
            application development. We not just only provide the development
            services but also help in doing the right marketing of your
            application.
          </p>
        </div>

        <div>
          <img src={MobileAppimg2} className="imageandroidtwo" />
        </div>
      </div>
      <BookFreeConsultation showmodal={handlemodal} closemodal={handleClose} />
      <div className="serviceBtn">
        <Button
          className="serviceButton"
          variant="primary"
          onClick={() => setHandleModal(true)}
        >
          Book Free Consultation
        </Button>
      </div>

      <Testimonial />

      <h3 className="additionalServiceHeading">
        Additional Services to scale your business
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          {cardOne.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
        <div className="cardLayoutAdditional">
          {cardTwo.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AndroidApp;
