import React, { useState, useEffect } from "react";
import Navbar from "../../Navbar/index";
import EmailMarketingbanner from "../../../images/EmailMarketingHeader.png";
import Testimonial from "../../Testimonial/Testimonial";
import WebApplication from "../../../images/webapplication.svg";
import EcommerceSol from "../../../images/ecommercesol.svg";
import SocialMedia from "../../../images/socialmedia.svg";
import MobileApp from "../../../images/mobileapp.svg";
import Footer from "../../Footer/index";
import "./EmailMarketing.css";
import Sidebar from "../../Sidebar/index";
import EmailMarketingImg1 from "../../../images/email-marketingimg1.png";
import Button from "react-bootstrap/Button";
import BookFreeConsultation from "../../BookFreeConsultation/BookFreeConsultation";
import { Helmet } from "react-helmet";
import Enquiry from "../../EnquiryForm/Enquiry";
import ReactPixel from "react-facebook-pixel";

const cardOne = [
  {
    image: WebApplication,
    name: "Search Engine Optimization",
    link: "/ecomseoservice",
    desc:
      "Our SEO specialists are committed to creating better evolving strategies in-line with the latest search engine guidelines to maintain your website's ranking.",
  },
  {
    image: EcommerceSol,
    name: "Digital Marketing",
    link: "/socialmediamarketing",
    desc:
      "Digital marketing gives you infinite opportunities to grow your business. We have an expert digital marketing team to help you gain maximum out of your digital marketing.",
  },
  {
    image: EcommerceSol,
    name: "Google Ad",
    link: "/googlead",
    desc:
      "We help you manage your business google ads campaign more efficiently and effectively by leveraging our team of google ads experts.",
  },
];

const cardTwo = [
  {
    image: SocialMedia,
    name: "Lead Generation",
    link: "/performancemarketing",
    desc:
      "We are specialized in lead generation digital marketing, not only that but we also help you receive the lead in real-time So that you can immediately convert the lead to customer.",
  },
  {
    image: MobileApp,
    name: "Social Media Marketing",
    link: "/socialmediamarketing",
    desc:
      "Your business needs an experienced and expert digital marketing agency to ensure your business growth and handle all your social media channels.",
  },
  {
    image: MobileApp,
    name: "Email Marketing",
    link: "/emailmarketing",
    desc:
      "Email marketing has the highest ROI as compared to any other marketing. Yodiso will provide the best email marketing and campaign solution for your business.",
  },
];

function EmailMarketing() {
  const [isOpen, setIsOpen] = useState(false);
  const [handlemodal, setHandleModal] = useState(false);

  const handleClose = () => {
    setHandleModal(false);
  };
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const advancedMatching = { em: "madhav.mishra@yodiso.com" };
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(277473760122474, advancedMatching, options);
    ReactPixel.pageView();
  }, []);
  return (
    <div style={{ height: 2000 }} style={{ scrollbarWidth: "none" }}>
      <Helmet>
        <title>YODISO | EMAIL MARKETING</title>
      </Helmet>
      <Enquiry />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <div className="navpagecontainer">
        <img src={EmailMarketingbanner} className="webbanner" />

        <div className="top-left"></div>
        <h2 className="centered">
          Latest Technology | User-Friendly | Low Maintenance
        </h2>
      </div>

      <div style={{ textAlign: "center", marginTop: 80 }}>
        <h1 className="servicesHeading">
          Email <span style={{ color: "blue" }}>Marketing</span>
        </h1>
      </div>

      <div className="briefContainerOne">
        <div>
          <img src={EmailMarketingImg1} className="imageemailmarketingone" />
        </div>
        <div className="briefingemailmarketingone">
          <h3 className="headeremailmarketingone">
            Highest ROI With Email Marketing
          </h3>
          <p style={{ marginTop: 30, textAlign: "justify" }}>
            Email marketing has the highest ROI as compared to any other
            marketing. Yodiso will provide the best email marketing and campaign
            solution for your business. We are all time trusted partners for our
            clients with their all email-marketing requirements.
          </p>
        </div>
      </div>

      <BookFreeConsultation showmodal={handlemodal} closemodal={handleClose} />
      <div className="serviceBtn">
        <Button
          className="serviceButton"
          variant="primary"
          onClick={() => setHandleModal(true)}
        >
          Book Free Consultation
        </Button>
      </div>

      <h3 className="additionalServiceHeading">
        What services we provide in email marketing:
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={WebApplication} />
            <p className="boxPara">Personalized email marketing</p>
            <p className="boxSubPara">
              We craft beautiful email templates with personalized contents and
              run advertisement.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Segment email marketing</p>
            <p className="boxSubPara">
              We will segment your customer data based on their interest and run
              the email marketing according to the segmented data.
            </p>
          </div>

          <div className="cardBoxAdditional">
            <img className="imageLogo" src={EcommerceSol} />
            <p className="boxPara">Mobile email marketing</p>
            <p className="boxSubPara">
              We will create email templates which are mobile optimized and run
              the email campaign keeping mobile view in mind.
            </p>
          </div>
        </div>

        <div className="cardLayoutAdditional">
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={SocialMedia} />
            <p className="boxPara">Automate Email Marketing</p>
            <p className="boxSubPara">
              We can help you in setting-up the automated email campaign which
              will automatically activated based on the set parameter.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">Campaign Marketing</p>
            <p className="boxSubPara">
              Looking for an Android or iOS App Development? We are one of the
              most trusted agency in Mumbai.
            </p>
          </div>
          <div className="cardBoxAdditional">
            <img className="imageLogo" src={MobileApp} />
            <p className="boxPara">The newsletter email marketing</p>
            <p className="boxSubPara">
              We will setup a newsletter email campaign for your customers.
            </p>
          </div>
        </div>
      </div>
      <Testimonial />

      <h3 className="additionalServiceHeading">
        Additional Services to scale your business
      </h3>

      <div className="cardLayoutContainer">
        <div className="cardLayoutAdditional">
          {cardOne.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
        <div className="cardLayoutAdditional">
          {cardTwo.map((card, key) => {
            return (
              <div key={key} className="cardBoxAdditional">
                <img className="imageLogo" src={card.image} />
                <p className="boxPara">
                  <a href={card.link}>{card.name}</a>
                </p>
                <p className="boxSubPara">{card.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default EmailMarketing;
