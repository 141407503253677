import React, { useState, useEffect } from "react";
import "./Enquiry.css";
import $ from "jquery";
import { Form, Modal, InputGroup, Card, Button } from "react-bootstrap";
import EnquiryLogo from "../../images/Yodiso_Enquiry.png";
import ReactPixel from "react-facebook-pixel";
import CheckedTick from "../../images/checked.gif";

function Enquiry() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [responseType, setResponseType] = useState("");
   const [submitted, setSubmitted] = useState(false);
  const successAlert = () => (
    <Modal
      size="sm"
      show={smShow}
      onHide={() => setSmShow(false)}
      aria-labelledby="example-modal-sizes-title-sm"
    >
      <Modal.Body closeButton>
        <strong style={{ textAlign: "center" }}>
          Thank you for contacting us, we will get back to you soon!
        </strong>
        <div className="successgif">
          <img src={CheckedTick} alt="loading..." width="200" height="200" />
        </div>
      </Modal.Body>
    </Modal>
  );

  const PostData = (e) => {
    e.preventDefault();
    fetch("/contactusfooter", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        phone,
        message,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setSubmitted(true);
        if (data.error) {
          console.log("error");
        } else {
          setSmShow(true);
          setResponseType("success");
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const advancedMatching = { em: "madhav.mishra@yodiso.com" };
    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init(277473760122474, advancedMatching, options);
    ReactPixel.pageView();
  }, []);
  $(document).ready(function () {
    $(".toggle").click(function () {
      $(".sidebar-contact").toggleClass("active");
      $(".toggle").toggleClass("active");
    });
  });

  return (
    <div>
      {submitted && (responseType === "error" ? "" : successAlert())}
      <script src="https://code.jquery.com/jquery-3.3.1.js"></script>
      <div className="sidebar-contact">
        <div className="toggle">
          <img src={EnquiryLogo} alt="logo" />
        </div>
        <h2 style={{ textAlign: "center", marginTop: -25 }}>Contact Us</h2>
        <div className="scroll">
          <Card>
            <Form noValidate>
              <Form.Row style={{ display: "flex", flexDirection: "column" }}>
                <Form.Group
                  style={{ width: 200, marginTop: -15 }}
                  controlId="validationCustom01"
                >
                  <Form.Label>Name *</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group
                  style={{ width: 200, marginTop: -15 }}
                  controlId="validationCustom02"
                >
                  <Form.Label>Contact No. *</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Group>

                <Form.Group
                  style={{ width: 200, marginTop: -15 }}
                  controlId="validationCustomUsername"
                >
                  <Form.Label>Email Address *</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="text"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="name@example.com"
                      aria-describedby="inputGroupPrepend"
                      required
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Label style={{ marginLeft: 5, marginTop: -15 }}>
                  Message
                </Form.Label>
                <Form.Control
                  style={{ width: 190, height: 50, marginLeft: 5 }}
                  size="sm"
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Message"
                />

                <Button
                  variant="primary"
                  style={{ marginTop: 20 }}
                  onClick={PostData}
                >
                  Send
                </Button>
              </Form.Row>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Enquiry;
