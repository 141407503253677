import "./App.css";
import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./pages";
import About from './components/InfoSection/index';
import WebDesign from "../src/components/WebServices/WebSiteDesigning/WebSiteDesign";
import WebDevelopment from "../src/components/WebServices/WebSiteDevelopment/WebsiteDevelop";
import WebMaintenance from "../src/components/WebServices/WebsiteMaintenance/WebsiteMaintenance";
import WebHosting from "../src/components/WebServices/WebsiteHosting/WebsiteHosting";
import WebApplication from "./components/WebServices/WebApplication/WebsiteApplication";
import CustomSoftware from "./components/WebServices/CustomSoftware/CustomSoftware";
import WordPressDev from "./components/WebServices/WordPressDevelopment/WordPressDev";
import AndroidAppDev from "./components/WebServices/AndroidAppDev/AndroidApp";
import IosAppDev from "./components/WebServices/IOSAppDev/IosAppDev";
import EcommerceWebsiteDev from "./components/Ecommerce-Solution/Ecommerce-WebsiteDev/WebsiteDevelopment";
import EcommerceSEOService from "./components/Ecommerce-Solution/Ecommerce-SEOService/SEOService";
import EcommerceWebsiteHosting from "./components/Ecommerce-Solution/Ecommerce-WebsiteHosting/WebsiteHosting";
import EcommerceWebsiteMaintenance from "./components/Ecommerce-Solution/Ecommerce-WebsiteMaintenance/WebsiteMaintenance";
import PaymentGateway from "./components/Ecommerce-Solution/PaymentGatewayIntegrate/PaymentIntegration";
import SSLCertificate from "./components/Ecommerce-Solution/SSLCertificate/SSLCerti";
import ContactUs from "./components/ContactUs/ContactUs";
import Career from "./components/Career/Career";
import PerformanceMarketing from "./components/Marketing-Services/Performance-Marketing/PerformanceMarketing";
import FacebookMarketing from "./components/Marketing-Services/Facebook-Marketing/FacebookMarketing";
import SocialMediaMarketing from "./components/Marketing-Services/SocialMedia-Marketing/SocialMediaMarketing";
import PayPerClick from "./components/Marketing-Services/PPC/PayPerClick";
import SocialMediaPageManagement from "./components/Marketing-Services/SocialMediaPage-Management/SocialMediaPageManagement";
import SocialMediaOptimization from "./components/Marketing-Services/SocialMedia-Optimization/SocialMediaOptimization";
import GoogleAd from "./components/Marketing-Services/GoogleAd/GoogleAd";
import EmailMarketing from "./components/Marketing-Services/EmailMarketing/EmailMarketing";
import BookFreeConsultation from './components/BookFreeConsultation/BookFreeConsultation';



function App() {
  
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/webdesign" component={WebDesign} />
        <Route path="/webdevelopment" component={WebDevelopment} />
        <Route path="/webmaintenance" component={WebMaintenance} />
        <Route path="/webhosting" component={WebHosting} />
        <Route path="/webapplication" component={WebApplication} />
        <Route path="/customsoftware" component={CustomSoftware} />
        <Route path="/wordpressdev" component={WordPressDev} />
        <Route path="/androidappdev" component={AndroidAppDev} />
        <Route path="/iosappdev" component={IosAppDev} />
        <Route path="/ecomwebsitedev" component={EcommerceWebsiteDev} />
        <Route exact path="/ecomseoservice" component={EcommerceSEOService} />
        <Route path="/ecomwebsitehosting" component={EcommerceWebsiteHosting} />
        <Route
          path="/ecomwebsitemaintenance"
          component={EcommerceWebsiteMaintenance}
        />
        <Route path="/ecompaymentgateway" component={PaymentGateway} />
        <Route path="/ecomsslcertificate" component={SSLCertificate} />
        <Route path="/contactus" component={ContactUs} />
        <Route path="/career" component={Career} />
        <Route path="/performancemarketing" component={PerformanceMarketing} />
        <Route path="/facebookmarketing" component={FacebookMarketing} />
        <Route path="/socialmediamarketing" component={SocialMediaMarketing} />
        <Route path="/payperclick" component={PayPerClick} />
        <Route
          path="/socialmediapagemanagement"
          component={SocialMediaPageManagement}
        />
        <Route
          path="/socialmediaoptimization"
          component={SocialMediaOptimization}
        />
        <Route path="/googlead" component={GoogleAd} />
        <Route path="/emailmarketing" component={EmailMarketing} />
        <Route path="/bookfreeconsultation" component={BookFreeConsultation} />
      </Switch>
    </>
  );
}

export default App;
